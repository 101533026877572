import { Button, Cancel, Confirmed, DIMENSIONS, PaymentDetails } from '@hqo/react-components-library';
import { StatusIconTitle } from '@hqo/react-components-library/dist/molecules/statusTitle';
import styled from 'styled-components';
import { PAYMENT_CONTENT_WIDTH } from 'shared/consts';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';

export const OverviewContainer = styled.div<{ isSwipeModalContent: boolean }>`
  display: grid;
  grid-gap: 5px;
  padding: ${({ isSwipeModalContent }) => (isSwipeModalContent ? 0 : '0 16px')};
`;

export const StyledTitleBlock = styled(TitleBlock)`
  .title {
    line-height: 32px;
  }
`;

export const Subtitle = styled.div`
  font: 400 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const TitleContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-top: 25px;
  }
`;

export const PaymentMethodContainer = styled.div`
  @media only screen and (min-width: ${DIMENSIONS.LARGE}) {
    padding: 0 12px;
  }
`;

export const SummaryContainer = styled.div`
  display: grid;
  grid-gap: 18px;
  box-sizing: border-box;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.$white};
  border-top: 1px solid ${({ theme }) => theme.colors.palette.systemGreyLight};
  position: initial;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 48px;
  padding: 12px 0;
  margin-top: auto;
`;

export const CtaButton = styled(Button)`
  width: 196px;
  height: 48px;
  padding: 16px;
  -webkit-tap-highlight-color: transparent;
  align-self: flex-end;

  @media only screen {
    width: 100%;
    font-size: 16px;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 0;
  }
`;

export const PaymentMethodTitle = styled.div`
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  margin-bottom: 23px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const CustomIconConfirmed = styled(Confirmed)`
  color: #04623e;
`;

export const CustomIconCancel = styled(Cancel)`
  color: ${({ theme }) => theme.colors.admin.pink};
`;

export const ServiceDetails = styled.div<{ isSwipeModalContent: boolean }>`
  margin: ${({ isSwipeModalContent }) => (isSwipeModalContent ? 0 : '0 16px')};

  @media only screen and (max-width: ${PAYMENT_CONTENT_WIDTH}px) {
    ${({ theme }) => `border-top: 1px solid ${theme.colors.greys.sharedMediumGrey};`}
    padding-top: 22px;
  }
`;

export const IconBlockContainer = styled.div`
  margin: 32px 0;
  display: grid;
  grid-gap: 23px;

  .icon-block-title {
    font-weight: bold;
  }
`;

export const StyledSummarySubtitle = styled.div`
  margin-bottom: 20px;
  font: 700 20px/23px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const StyledStatusIconTitle = styled(StatusIconTitle)`
  flex-direction: row-reverse;
  gap: 16px;
  margin-bottom: 8px;
`;

export const StyledPaymentDetails = styled(PaymentDetails)`
  margin-top: 0;

  @media only screen and (min-width: ${DIMENSIONS.LARGE}) {
    padding: 0 12px;
  }
`;

export const PaymentDetailsItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin: 2px 0 0 7px;
`;

export const CancelButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  width: 100%;
  height: auto;
`;

export const CancelButton = styled(Button)`
  display: flex;
  width: 163px;
  height: auto;
  color: ${({ theme }) => theme.colors.palette.systemPink};
  border: 1px solid ${({ theme }) => theme.colors.palette.systemPink};
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
`;
