import {
  ACTION_STATUSES,
  CREDIT_PAYMENT_TYPE_ID,
  INVOICE_PAYMENT_TYPE_ID,
  ORDER_TYPES,
  TRACK_EVENT_TYPES,
  TRACK_EVENTS,
} from 'shared/consts';
import {
  CloseButtonContainer,
  ContentContainer,
  ErrorLabel,
  ErrorWrapper,
  QuickCheckoutContent,
  StyledErrorIcon,
  StyledPaymentBlock,
  StyledPaymentMethod,
  StyledPriceBlock,
  StyledTitleBlock,
} from './styles';
import { CREDIT_POINTS, INVOICE } from 'shared/consts/payment-method-types';
import { PaymentMethodTypes, SavedPaymentMethod } from 'store/payment/types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { selectBuildingLocale } from 'store/building/selectors';
import {
  selectCartDiscountSummary,
  selectComplete3DSCartStatus,
  selectSubmitCartError,
  selectSubmitCartStatus,
  shouldSubmit3DSecure,
} from 'store/cart/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from './button';
import { CloseButton } from 'components/close-button';
import { ItemQuantityBlock } from '@hqo/react-components-library/dist/molecules/itemQuantityBlock';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { getCartErrorMessage } from 'utils/getCartErrorMessage';
import { getPrice } from 'utils/getPrice';
import { formatCartItemsIds } from 'utils/formatTrackEventProps';
import { track } from '@hqo/web-tracking';
import { useIntl } from 'react-intl';
import { getTransaction } from 'store/transactions/actions';
import { AppliedCreditsMethod } from './applied-credits-method';
import { resetComplete3DSCart, submitCart } from 'store/cart/actions';
import { usePaymentLabels } from './hooks/use-payment-labels.hook';
import { Props } from './types';
import { useCheckoutScreen } from 'hooks/payment-content/use-checkout-screen.hook';
import qs from 'qs';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { selectInitialRoute } from 'store/routes/selectors';
import { useLocation } from 'react-router-dom';

export const Container = ({
  cart,
  paymentMethods,
  currentPaymentMethodId,
  setProcessPaymentFailed,
  isProcessPaymentFailed,
  closeQuickCheckoutHandler,
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const contentRef = useRef(null);
  const submit3DSecure = useSelector(shouldSubmit3DSecure);
  const complete3DSCartStatus = useSelector(selectComplete3DSCartStatus);
  const cartError = useSelector(selectSubmitCartError);
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';
  const discountSummary = useSelector(selectCartDiscountSummary);
  const initialRoute = useSelector(selectInitialRoute);
  const { total, currencyType } = formatSummaryValues(cart?.total_summary);
  const cartItemsIds = formatCartItemsIds(cart);
  const isPaymentMethodsAvailable = !!paymentMethods?.length;
  const isFreeCart = !cart.total_summary.total;
  const { ...queryParams } = useSearchParams();
  const { openCheckout } = useCheckoutScreen();
  const paymentMethod = useMemo<SavedPaymentMethod>(() => {
    const creditPointsMethod = paymentMethods?.find(method => method?.payment_method_type === CREDIT_POINTS);
    if (creditPointsMethod && creditPointsMethod.points_balance >= cart.total_summary.total) {
      return creditPointsMethod;
    }

    return paymentMethods?.find(
      method => method?.id?.toString() === currentPaymentMethodId || method?.payment_method_type === INVOICE,
    );
  }, [paymentMethods]);
  const {
    paymentMethodLabels,
    resolveSubtitleId,
    resolveCheckoutButtonId,
    resolveCheckoutItemSubtitleText,
    resolvePaymentMethodText,
  } = usePaymentLabels(isPaymentMethodsAvailable, isFreeCart, paymentMethod);
  const { eventWeekDay, eventDateMonthLong, formatEventStartTime, eventDateDay } = paymentMethodLabels || {};

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getTransaction.request(cart.transaction_uuid));
    }
  }, [cart.transaction_uuid, dispatch, submitCartStatus]);

  const isVisiblePaymentMethodBlock =
    !isFreeCart ||
    (!isFreeCart && paymentMethod?.payment_method_type === INVOICE) ||
    cart.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP;

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.REJECTED) {
      setProcessPaymentFailed();
    }
  }, [setProcessPaymentFailed, submitCartStatus]);

  useEffect(() => {
    track(
      TRACK_EVENTS.QUICK_CHECKOUT_IMPRESSION,
      {
        type: TRACK_EVENT_TYPES.IMPRESSION,
        cart_total: total,
        currency_code: currencyType,
        promo_code_applied: !!discountSummary,
        cart_type: cart.type,
        items: cartItemsIds,
        adapter: cart.config?.config?.adapter,
      },
      { sendToPendo: true, sendToHqoTracking: true },
    );
  }, []);

  const onEditClick = useCallback(() => {
    openCheckout();
  }, [openCheckout]);

  const trackCheckoutClick = useCallback(() => {
    track(
      TRACK_EVENTS.CHECKOUT_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
        payment_method: paymentMethod?.payment_method_type,
        cart_total: total,
        currency_code: currencyType,
        promo_code_applied: !!discountSummary,
        cart_type: cart.type,
        items: cartItemsIds,
        adapter: cart.config?.config?.adapter,
      },
      { sendToPendo: true, sendToHqoTracking: true },
    );
  }, []);

  const onCTAClick = useCallback(() => {
    if (!isPaymentMethodsAvailable && !isFreeCart) {
      openCheckout();
    }
    if (isFreeCart && cart.type !== ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP) {
      trackCheckoutClick();
      dispatch(
        submitCart.request({
          cart_id: cart.id as string,
        }),
      );
    } else if (isPaymentMethodsAvailable) {
      trackCheckoutClick();
      const paymentInfo = {
        cart_id: cart.id as string,
        payment_method_id: paymentMethod?.id.toString(),
      };
      if (complete3DSCartStatus === ACTION_STATUSES.REJECTED) {
        dispatch(resetComplete3DSCart());
      }

      const searchString = qs.stringify({
        ...queryParams,
        cartId: cart?.id,
        paymentId: paymentInfo.payment_method_id,
        initialRoute,
        redirectRoute: pathname,
      });
      dispatch(
        submitCart.request(
          submit3DSecure &&
            paymentMethod?.id.toString() !== CREDIT_PAYMENT_TYPE_ID &&
            paymentMethod?.id.toString() !== INVOICE_PAYMENT_TYPE_ID
            ? {
                ...paymentInfo,
                browser_info: window.Spreedly?.ThreeDS?.serialize('01'),
                attempt_3dsecure: true,
                three_ds_version: '2',
                callback_url: `${window.location.origin}/redirect?${searchString}`,
                redirect_url: `${window.location.origin}/redirect?${searchString}`,
              }
            : paymentInfo,
        ),
      );
    }
  }, [
    isFreeCart,
    isPaymentMethodsAvailable,
    trackCheckoutClick,
    dispatch,
    cart,
    paymentMethod?.id,
    initialRoute,
    pathname,
  ]);

  return (
    <ContentContainer>
      <CloseButtonContainer>
        <CloseButton onClose={closeQuickCheckoutHandler} />
      </CloseButtonContainer>
      <QuickCheckoutContent className="quick-checkout-content" ref={contentRef}>
        <StyledTitleBlock
          spacing={20}
          title={intl.formatMessage({ id: 'checkout' })}
          subtitle={intl.formatMessage(
            { id: resolveSubtitleId() },
            cart.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP
              ? {
                  serviceName: cart.items?.[0]?.display_info.title,
                }
              : {
                  instructorName: cart.items?.[0]?.display_info?.description1,
                  serviceName: cart.items?.[0]?.display_info.title,
                  serviceTime: `${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}, ${intl.formatMessage({
                    id: 'at',
                  })} ${formatEventStartTime}`,
                },
          )}
        />
        <StyledPriceBlock>
          {cart.items?.map(item => (
            <ItemQuantityBlock
              key={item.id}
              title={item.display_info.title}
              subtitle={resolveCheckoutItemSubtitleText(item)}
              price={getPrice(isFreeCart, cart, intl.formatMessage({ id: 'free' }), buildingLocale)}
            />
          ))}
        </StyledPriceBlock>
        <StyledPaymentBlock>
          {isVisiblePaymentMethodBlock && (
            <StyledPaymentMethod
              methodText={
                currentPaymentMethodId &&
                resolvePaymentMethodText(paymentMethod?.payment_method_type as PaymentMethodTypes)
              }
              paymentMethod={paymentMethod?.payment_method_type as PaymentMethodTypes}
              editButtonText={intl.formatMessage({ id: 'edit' })}
              onEditClick={onEditClick}
            />
          )}
          <AppliedCreditsMethod cart={cart} />
          {isProcessPaymentFailed && (
            <ErrorWrapper>
              <ErrorLabel>{intl.formatMessage({ id: getCartErrorMessage(cartError) })}</ErrorLabel>
              <StyledErrorIcon size="sm" variant="pink" />
            </ErrorWrapper>
          )}
        </StyledPaymentBlock>
      </QuickCheckoutContent>
      <Button
        text={intl.formatMessage({ id: resolveCheckoutButtonId() })}
        isFreeCart={isFreeCart}
        isPaymentMethodsAvailable={isPaymentMethodsAvailable}
        onClick={onCTAClick}
        isDisabled={submitCartStatus === ACTION_STATUSES.PENDING || submitCartStatus === ACTION_STATUSES.FULFILLED}
        isLoading={submitCartStatus === ACTION_STATUSES.PENDING}
      />
    </ContentContainer>
  );
};
