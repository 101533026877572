export const DEFAULT_BRAND = 'HQO';

export const API_URL_LS_KEY = 'apiUrl';
export const APP_BRAND_LS_KEY = 'appBrand';
export const BUILDING_UUID_LS_KEY = 'buildingUuid';
export const LOCALE_LS_KEY = 'locale';
export const SDK_APP_UUID = '0d9949ac-f107-4410-a54a-8e4004cdcd48';
export const DEFAULT_3DSECURE_VERSION = '2';
export const TOKENIZATION_METHOD = 'Spreedly';

export const EXPDATE_LENGTH = 5;
export const MIN_USERNAME_LENGTH = 1;
export const MIN_ZIP_CODE_LENGTH = 5;

export const SPREEDLY_CARD_REF = 'number';
export const SPREEDLY_CVV_REF = 'cvv';
export const SPREEDLY_CARD_ID = 'spreedly-credit-card';
export const SPREEDLY_CVV_ID = 'spreedly-cvv';

export const REGISTERED_SIGN = '\u00ae';

export enum USER_AGENT {
  CHROME = 'chrome',
  SAFARI = 'safari',
}

export const LAUNCH_DARKLY_ANONYMOUS_EMAIL = 'anonymous@hqo.co';

export enum OwnerType {
  BUILDING = 'building',
  BUILDING_COMPANY = 'building_company',
  COMPANY = 'company',
}

export const DEFAULT_OWNER_TYPE = OwnerType.BUILDING;
export const IMG_URL = 'https://hqo.imgix.net/';

export const DEVICE_WIDTHS = {
  SMALL: 768,
  MEDIUM: 984,
  LARGE: 1080,
};

export const DEFAULT_MODAL_WIDTH = '425px';
export const DEFAULT_MODAL_HEIGHT = '299px';
export const DEFAULT_MODAL_PADDINGS = ['48px', '32px', '48px', '32px'];

export enum ACTION_STATUSES {
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export const WEEK_LENGTH = 7;
export const CLASS_CUT_OFF_MONTH_NUMBER = 24;
export const SERVICE_SEATS_AVAILABLE_WARNING_THRESHOLD = 5;

export enum SERVICE_PROVIDER_ADAPTERS {
  MINDBODY = 'MindbodyProviderAdapter',
  NATIVE = 'NativeProviderAdapter',
}

export const DEFAULT_LOCALE = 'en';
export const DEFAULT_TIMEZONE = 'America/New_York';

export enum FontLocation {
  TITLE = 'title',
  HEADING = 'heading',
  BODY = 'body',
}

export enum SCHEDULE_TYPES {
  CLASS = 'class',
  APPOINTMENT = 'appointment',
}

export enum SCHEDULE_TYPES_PARAMS {
  CLASSES = 'classes',
  APPOINTMENTS = 'appointments',
}

export enum SIZE_VARIANTS {
  SMALL = 'small',
  NORMAL = 'normal',
  WIDE = 'wide',
}

export enum PAGES {
  REGISTRATION = 'registration',
  CONTRACT_LIST = 'contract_list',
  AGREEMENT = 'agreement',
}

export enum CATEGORIES {
  MINDBODY = 'mindbody',
}

export type searchParams = { [key: string]: string };

export const DEFAULT_LINK_ATTRIBUTES = {
  target: '_blank',
  rel: 'noreferrer',
};
