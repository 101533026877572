import { useDispatch, useSelector } from 'react-redux';

import { Building } from 'store/building/types';
import { getBuilding } from 'store/building/actions';
import { selectBuilding } from 'store/building/selectors';
import { useEffect } from 'react';
import { configSelector } from 'store/config/selectors';
import { hasToken } from 'store/user/selectors';
import { USER_AGENT } from 'shared/consts';

// eslint-disable-next-line max-lines-per-function
export const useBuilding = (): Building => {
  const { buildingUuid } = useSelector(configSelector);
  const dispatch = useDispatch();
  const building = useSelector(selectBuilding);
  const hasUserToken = useSelector(hasToken);
  const isDesktopApp =
    navigator.userAgent.toLowerCase().includes(USER_AGENT.CHROME) ||
    navigator.userAgent.toLowerCase().includes(USER_AGENT.SAFARI);

  useEffect(() => {
    let getBuildingTimeout: NodeJS.Timeout;

    if (!isDesktopApp) {
      if (buildingUuid && hasUserToken && !building) {
        dispatch(getBuilding.request(buildingUuid as string));
      }
    } else {
      getBuildingTimeout = setTimeout(() => {
        if (buildingUuid && hasUserToken && !building) {
          dispatch(getBuilding.request(buildingUuid as string));
        }
      }, 1000);
    }

    return () => {
      clearTimeout(getBuildingTimeout);
    };
  }, [buildingUuid, hasUserToken, building, isDesktopApp]);

  return building;
};
