import { ButtonContainer, CancelButton, ErrorText } from './styles';
import { ACTION_STATUSES, PATCH_STATUSES } from 'shared/consts';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectPatchTransactionStatus, selectPatchTransactionType } from 'store/transactions/selectors';

interface CancelButtonBlockProps {
  onCancel: VoidFunction;
}

export const CancelButtonBlock = ({ onCancel }: CancelButtonBlockProps) => {
  const intl = useIntl();
  const patchTransactionStatus = useSelector(selectPatchTransactionStatus);
  const patchTransactionType = useSelector(selectPatchTransactionType);

  const isLoadingState =
    patchTransactionStatus === ACTION_STATUSES.PENDING && patchTransactionType === PATCH_STATUSES.CANCELLED;

  const isErrored = patchTransactionStatus === ACTION_STATUSES.REJECTED;

  return (
    <ButtonContainer>
      {isErrored && <ErrorText>{intl.formatMessage({ id: 'cancel_booking_error' })}</ErrorText>}
      <CancelButton
        data-testid="cancel-button"
        text={intl.formatMessage({ id: 'cancel_book' })}
        onClick={onCancel}
        loading={isLoadingState}
        disabled={isLoadingState}
        variant="secondary"
      />
    </ButtonContainer>
  );
};
