/* eslint-disable max-lines-per-function */
import {
  ACTION_STATUSES,
  CHECKOUT_PATH,
  DEFAULT_PATH,
  OwnerType,
  PAYMENT_MINIAPP_POST_MESSAGES,
  QUICK_CHECKOUT_PATH,
  RECEIPT_PATH,
  ROOT_PATH,
} from 'shared/consts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { OrderType } from 'store/cart/types';
import { ProviderParams } from 'shared/types';

import { getCurrentServiceProvider } from 'store/serviceProviders/actions';
import { patchTransaction, resetPatchTransaction } from 'store/transactions/actions';
import { selectCurrentAvailableService } from 'store/availableServices/selectors';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { replace } from 'connected-react-router';
import { selectCurrentMembership } from 'store/serviceMemberships/selectors';
import { selectPatchTransactionId, selectPatchTransactionStatus } from 'store/transactions/selectors';
import { RegistrationContractAgreementStep } from 'shared/consts/service-provider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import qs from 'qs';
import { useIframeParams } from 'hooks/payment-hook/use-iFrame-params.hook';
import { useMiniappSdk } from 'hooks/use-miniapp-sdk.hook';
import { useToggleSwipeGestures } from 'hooks/use-toggle-swipe-gestures.hook';
import { configSelector } from 'store/config/selectors';
import { selectInitialRoute } from 'store/routes/selectors';
import { useLocale } from 'hooks/use-locale.hook';

interface UseIFrameReturnValues {
  isContentLoading: boolean;
  handleIframeLoading: VoidFunction;
  handleGoBack: VoidFunction;
  isCheckoutDesktop: boolean;
  isQuickCheckout: boolean;
  iFrameUrl: string;
  quickCheckout: boolean;
  isSwipeModalContent: boolean;
  handleClose: VoidFunction;
  isReceipt: boolean;
  isFullCheckout: boolean;
  is3DSChallenge: boolean;
}

const mapPaymentIFramePath: Record<string, string> = {
  receipt: RECEIPT_PATH,
  checkout: CHECKOUT_PATH,
};

export const useIFrame = (
  currentOrderType?: OrderType,
  toggleReverseAnimation?: VoidFunction,
): UseIFrameReturnValues => {
  const { companyUuid, cartId } = useParams<ProviderParams>();
  const { url: currentUrl } = useRouteMatch();
  const { quickCheckout, swipePaymentIFramePath, transactionUuid, error, paymentId, ...restQueryParams } =
    useSearchParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const currentProviderPath = `${ROOT_PATH}/${companyUuid}`;
  const { buildingUuid } = useSelector(configSelector);
  const locale = useLocale();
  const route = useSelector(selectInitialRoute);

  const [isCheckoutDesktop, setIsCheckoutDesktop] = useState<boolean>(false);
  const [is3DSChallenge, setIs3DSChallenge] = useState<boolean>(false);
  const [isQuickCheckout, setIsQuickCheckout] = useState<boolean>(false);
  const [isContentLoading, setContentLoading] = useState<boolean>(true);
  const [isReceipt, setIsReceipt] = useState<boolean>(false);
  const [isFullCheckout, setIsIsFullCheckout] = useState<boolean>(false);
  const { showMiniappPaymentsNavigation } = useFlags();
  const currentAvailableService = useSelector(selectCurrentAvailableService);
  const patchTransactionStatus = useSelector(selectPatchTransactionStatus);
  const patchTransactionId = useSelector(selectPatchTransactionId);
  const currentMembership = useSelector(selectCurrentMembership);
  const isMobileDevice = useIsSmallViewportWidth();
  const miniappPaymentPath: string = swipePaymentIFramePath
    ? mapPaymentIFramePath[swipePaymentIFramePath]
    : QUICK_CHECKOUT_PATH;

  const isQuickCheckoutIframe = miniappPaymentPath === QUICK_CHECKOUT_PATH;
  const initialRoute = useMemo<string>(() => (route === DEFAULT_PATH ? DEFAULT_PATH : route), [route]);

  const isSwipeModalContent: boolean = showMiniappPaymentsNavigation && !!swipePaymentIFramePath;
  const client = useMiniappSdk();
  useToggleSwipeGestures();

  const pathForHandleClose = useMemo<string>(
    () => `${initialRoute}?locale=${locale}&buildingUuid=${buildingUuid}`,
    [initialRoute, locale, buildingUuid],
  );

  useEffect(() => {
    if ((!showMiniappPaymentsNavigation && (isReceipt || isFullCheckout)) || isSwipeModalContent) {
      client?.header.hideHeader();
    }
    return () => client?.header.showHeader();
  }, [client, isFullCheckout, isReceipt, isSwipeModalContent, showMiniappPaymentsNavigation]);

  const generateClassOrderRedirectPath = useCallback(() => {
    if (!isReceipt) {
      const path = isSwipeModalContent ? '/swipe-payment' : '/payment';

      return currentUrl.split(path)[0];
    }
    return currentProviderPath;
  }, [isReceipt, currentProviderPath, isSwipeModalContent, currentUrl]);

  const getPathForHandleClose = useCallback(() => {
    const path = isSwipeModalContent ? '/swipe-payment' : '/payment';

    switch (currentOrderType) {
      case OrderType.SERVICE_BOOKING_APPOINTMENT:
      case OrderType.SERVICE_BOOKING_CLASS:
        return generateClassOrderRedirectPath();
      case OrderType.SERVICE_BOOKING_MEMBERSHIP:
        return isSwipeModalContent && swipePaymentIFramePath === 'checkout'
          ? `${currentUrl.split(path)[0]}?step=${
              RegistrationContractAgreementStep.AGREEMENT
            }&selected-plan=${encodeURIComponent(currentMembership.id)}`
          : currentProviderPath;
      default:
        return currentProviderPath;
    }
  }, [
    currentMembership,
    currentOrderType,
    currentProviderPath,
    currentUrl,
    generateClassOrderRedirectPath,
    isSwipeModalContent,
    swipePaymentIFramePath,
  ]);

  const getPathForHandleGoBack = useCallback(() => {
    const path = isSwipeModalContent ? '/swipe-payment' : '/payment';
    switch (currentOrderType) {
      case OrderType.SERVICE_BOOKING_APPOINTMENT:
      case OrderType.SERVICE_BOOKING_CLASS:
        return currentUrl.split(path)[0];
      case OrderType.SERVICE_BOOKING_MEMBERSHIP:
        return `${currentUrl.split(path)[0]}?step=${
          RegistrationContractAgreementStep.AGREEMENT
        }&selected-plan=${encodeURIComponent(currentMembership.id)}`;
      default:
        return currentProviderPath;
    }
  }, [currentMembership, currentOrderType, currentProviderPath, currentUrl, isSwipeModalContent]);

  const handleClose = useCallback(() => {
    if (isReceipt) {
      dispatch(replace(pathForHandleClose));
    } else {
      dispatch(replace(getPathForHandleClose()));
    }
  }, [dispatch, pathForHandleClose, isReceipt, getPathForHandleClose]);

  const handleGoBack = useCallback(
    () => dispatch(replace(getPathForHandleGoBack())),
    [dispatch, getPathForHandleGoBack],
  );

  const onMessage = useCallback(
    (event: MessageEvent): void => {
      if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CLOSE) {
        dispatch(resetPatchTransaction());
        toggleReverseAnimation?.();
        setTimeout(() => {
          handleClose();
          toggleReverseAnimation?.();
        }, 400);
        if (isReceipt) {
          dispatch(
            getCurrentServiceProvider.request({
              ownerUuid: buildingUuid,
              ownerType: OwnerType.BUILDING,
              companyUuid,
            }),
          );
        }
      }
      if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_3DS_SUCCEEDED) {
        if (showMiniappPaymentsNavigation && isMobileDevice && isQuickCheckout) {
          const queryParams = {
            ...restQueryParams,
            swipePaymentIFramePath: 'receipt',
            transactionUuid: event.data.value,
            error: false,
          };
          const queryString = qs.stringify(queryParams);
          dispatch(replace(`${pathname.replace('/payment', '/swipe-payment')}?${queryString}`));
        }
      }
      if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_3DS_STATUS_UPDATE) {
        const { transaction_id: transactionId, status_3ds } = event.data.value;
        if (patchTransactionStatus !== ACTION_STATUSES.PENDING && transactionId !== patchTransactionId) {
          dispatch(
            patchTransaction.request({
              transactionId,
              status_3ds,
              cartId,
            }),
          );
        }
      }
      if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_3DS_REDIRECT_ERROR) {
        if (showMiniappPaymentsNavigation && isMobileDevice && isQuickCheckout) {
          const queryParams = {
            ...restQueryParams,
            swipePaymentIFramePath: 'checkout',
            error: true,
            paymentId: event.data.paymentId,
          };
          const queryString = qs.stringify(queryParams);
          dispatch(replace(`${pathname.replace('/payment', '/swipe-payment')}?${queryString}`));
        }
      }
      if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_GO_BACK) {
        handleGoBack();
      }
      if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_RECEIPT) {
        setIsQuickCheckout(false);
        setIsCheckoutDesktop(false);
        setIsReceipt(true);
      }
      if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CHECKOUT_MOBILE) {
        setIsQuickCheckout(false);
        setIsIsFullCheckout(true);
      }
      if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CHECKOUT_DESKTOP) {
        setIsQuickCheckout(false);
        setIsCheckoutDesktop(true);
      }
      if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_FULL_CHECKOUT_SUBMIT_CLICK) {
        if (swipePaymentIFramePath === 'checkout') {
          dispatch(replace(`${currentUrl}?swipePaymentIFramePath=receipt`));
        }
      }
      if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_QUICK_CHECKOUT) {
        setIsQuickCheckout(true);
        setIs3DSChallenge(false);
      }
      if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_EDIT_BUTTON_CLICK) {
        if (showMiniappPaymentsNavigation && isMobileDevice) {
          const queryParams = {
            ...restQueryParams,
            swipePaymentIFramePath: 'checkout',
          };
          const queryString = qs.stringify(queryParams);
          dispatch(replace(`${pathname.replace('/payment', '/swipe-payment')}?${queryString}`));
        }
      }
      if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_DONE_BUTTON_CLICK) {
        if (showMiniappPaymentsNavigation && isMobileDevice) {
          const queryParams = {
            ...restQueryParams,
            swipePaymentIFramePath: 'receipt',
            transactionUuid: event.data.transactionUuid,
            error: false,
          };
          const queryString = qs.stringify(queryParams);
          dispatch(replace(`${pathname.replace('/payment', '/swipe-payment')}?${queryString}`));
        }
      }
      if (event.data?.message === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_QUICK_CHECKOUT_3DS_CHALLENGE) {
        setIs3DSChallenge(true);
      }
    },
    [
      currentAvailableService,
      currentUrl,
      dispatch,
      handleClose,
      handleGoBack,
      isMobileDevice,
      isReceipt,
      pathname,
      buildingUuid,
      companyUuid,
      restQueryParams,
      showMiniappPaymentsNavigation,
      swipePaymentIFramePath,
      toggleReverseAnimation,
      isQuickCheckout,
      patchTransactionStatus,
      cartId,
    ],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  const handleIframeLoading = useCallback(() => {
    setContentLoading(false);
  }, [setContentLoading]);

  const iFrameUrl = useIframeParams(
    cartId,
    !!quickCheckout,
    showMiniappPaymentsNavigation,
    isQuickCheckoutIframe,
    transactionUuid,
    error,
    paymentId,
  );

  return {
    isContentLoading,
    handleIframeLoading,
    handleGoBack,
    iFrameUrl,
    isCheckoutDesktop,
    isQuickCheckout,
    quickCheckout,
    isSwipeModalContent,
    handleClose,
    isReceipt,
    isFullCheckout,
    is3DSChallenge,
  };
};
