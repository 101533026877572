import * as availableServicesActions from 'store/availableServices/actions';
import * as buildingActions from 'store/building/actions';
import * as cartActions from 'store/cart/actions';
import * as configActions from 'store/config/actions';
import * as errorsActions from 'store/errors/actions';
import * as serviceMembershipsActions from 'store/serviceMemberships/actions';
import * as serviceProvidersActions from 'store/serviceProviders/actions';
import * as serviceRegistrationActions from 'store/serviceRegistration/actions';
import * as transactionsActions from 'store/transactions/actions';
import * as themeActions from 'store/theme/actions';
import * as userActions from 'store/user/actions';
import * as featureFlagsActions from 'store/featureFlags/actions';
import * as routesActions from 'store/routes/actions';
import * as addCardActions from 'store/add-card/actions';
import * as paymentActions from 'store/payment/actions';

import { CallHistoryMethodAction, routerActions } from 'connected-react-router';
import { LocationState, Path } from 'history';

import { ActionType } from 'typesafe-actions';

// TODO: remove RouterActions custom types after https://github.com/supasate/connected-react-router/issues/286 fixed
type Push = (path: Path, state?: LocationState) => CallHistoryMethodAction<[Path, LocationState?]>;
type Replace = (path: Path, state?: LocationState) => CallHistoryMethodAction<[Path, LocationState?]>;

interface RouterActions {
  push: Push;
  replace: Replace;
  // go: Go; etc.
}

const actions = {
  router: routerActions as RouterActions,
  featureFlags: featureFlagsActions,
  user: userActions,
  theme: themeActions,
  serviceProviders: serviceProvidersActions,
  config: configActions,
  errors: errorsActions,
  serviceRegistration: serviceRegistrationActions,
  serviceMemberships: serviceMembershipsActions,
  transactions: transactionsActions,
  availableServices: availableServicesActions,
  cart: cartActions,
  building: buildingActions,
  routes: routesActions,
  addCard: addCardActions,
  payment: paymentActions,
};

export type RootAction = ActionType<typeof actions>;
export default actions;
