import { ModalWrapper, Image, DIMENSIONS, PaymentDetails } from '@hqo/react-components-library';
import { PAYMENT_CONTENT_WIDTH } from 'shared/consts';
import styled from 'styled-components';

const modalContainerTopMargin = '25px';

export const StyledModalWrapper = styled(ModalWrapper)`
  width: ${PAYMENT_CONTENT_WIDTH}px;

  @media only screen and (max-width: ${PAYMENT_CONTENT_WIDTH}px) {
    width: 100%;
  }
`;

export const ModalContainer = styled.div`
  height: calc(100% - ${modalContainerTopMargin});
  width: 100%;
  margin: 0;
  position: relative;
  overflow-y: scroll;
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  background: ${({ theme }) => theme.colors.$white};
  position: initial;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 48px;
  padding: 0;
  margin-top: auto;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ModalContentTitle = styled.div`
  font: 700 24px/28.13px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-top: 20px;
`;

export const ModalContentSubtitle = styled.div`
  font: 400 17px/19.92px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-top: 20px;
`;

export const TitleContainer = styled.div`
  padding: 0;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-top: 25px;
  }
`;

export const CheckoutWrapper = styled.div``;

export const DesktopTitleContainer = styled.div`
  padding: 0 16px;
  margin-bottom: 16px;
`;

export const StyledImage = styled(Image)`
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 215px;
  object-fit: cover;
`;

export const RightPaneContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.$greyLight};
  height: 100%;
`;

export const LeftPaneContainer = styled.div`
  margin-top: 62px;
  margin-left: 16px;
`;

export const DesktopContentContainer = styled.div`
  height: 550px;
  overflow-y: scroll;
`;

export const DesktopModalContainer = styled.div`
  width: 872px;
  height: 100%;
  overflow: hidden;
`;

export const DesktopButtonContainer = styled.div`
  background: ${({ theme }) => theme.colors.$white};
  position: fixed;
  left: 32px;
  bottom: 32px;
  width: 160px;
  height: 36px;
`;

export const StyledPaymentDetails = styled(PaymentDetails)`
  margin-top: 26px;

  @media only screen and (min-width: ${DIMENSIONS.LARGE}) {
    padding: 0 12px;
  }
`;
