import React from 'react';
import { formatDate, getEventDuration } from 'utils';
import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';
import { Service } from 'store/availableServices/types';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';

interface ServiceCalendarProps {
  selectedService: Service;
  locale: string;
  showDuration?: boolean;
}

export const ServiceCalendar = ({
  selectedService,
  locale,
  showDuration = true,
}: ServiceCalendarProps): JSX.Element => {
  const timeZone = useTimeZone();
  const { serviceBookingLocalTimeUpdate: inLocalTimeLD } = useFlags();
  const startAtString = selectedService?.availabilities?.[0]?.start_at ?? selectedService?.start_at;
  const endAtString = selectedService?.availabilities?.[0]?.end_at ?? selectedService?.end_at;

  const startAt = moment(startAtString).tz(timeZone);
  const endAt = moment(endAtString).tz(timeZone);
  const formattedStartTime = startAt.clone().format('h:mm');
  const formattedStartAmPm = startAt.clone().format('a');
  const formattedEndTime = endAt.clone().format('h:mm');
  const formattedEndAmPm = endAt.clone().format('a');

  const { eventWeekDayLong, eventDateMonthLong, eventDateDay, eventDateYear } = formatDate(
    startAtString,
    locale,
    timeZone,
    inLocalTimeLD,
  );

  const subtitle = showDuration
    ? `${formattedStartTime} / ${getEventDuration(startAtString, endAtString)} min`
    : `${formattedStartTime}${
        formattedStartAmPm !== formattedEndAmPm ? formattedStartAmPm : ''
      } - ${formattedEndTime}${formattedEndAmPm}`;

  return (
    <IconBlock
      icon="calendar"
      iconType="fal"
      title={`${eventWeekDayLong}, ${eventDateMonthLong} ${eventDateDay}, ${eventDateYear}`}
      subtitle={subtitle}
    />
  );
};
