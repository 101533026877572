import { BookingStatus, ORDER_TYPES, TRANSACTION_TYPES, TransactionDetailsServiceTypes } from 'shared/consts';
import {
  CancelButton,
  CancelButtonContainer,
  CustomIconCancel,
  CustomIconConfirmed,
  OverviewContainer,
  StyledStatusIconTitle,
  Subtitle,
  TitleContainer,
} from './styles';
import { LabelBlock } from '@hqo/react-components-library/dist/molecules/label-block/labelBlock';
import { IconContentProps } from '@hqo/react-components-library/dist/icons';
import { formatDate, getFormattedEventTimeRange } from 'utils/formatDate';
import { BoldText } from 'hocs/bold-text';
import React, { useMemo } from 'react';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { selectCart } from 'store/cart/selectors';
import { selectTransaction, selectTransactionDisplay } from 'store/transactions/selectors';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { selectCurrentAvailableService } from 'store/availableServices/selectors';
import { useReceipt } from 'components/payment/receipt/use-receipt.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ReceiptOverviewProps {
  isSwipeModalContent?: boolean;
}

export const ReceiptOverview = ({ isSwipeModalContent = false }: ReceiptOverviewProps) => {
  const intl = useIntl();
  const locale = useLocale();
  const { handleCancelClick } = useReceipt();
  const cart = useSelector(selectCart);
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const { transactionUuid } = useSearchParams();
  const transactionDisplay = useSelector(selectTransactionDisplay);
  const transaction = useSelector(selectTransaction);
  const isTransactionCancelled = transaction?.details?.status === BookingStatus.CANCELLED;
  const service = useSelector(selectCurrentAvailableService);
  const { enableServiceBookingCancellation } = useFlags();
  const isEventType =
    transaction?.details?.service?.type === TransactionDetailsServiceTypes.EVENT ||
    cart?.type === ORDER_TYPES.SERVICE_BOOKING_EVENT;

  const icon = useMemo(
    () => (transaction?.details?.status === BookingStatus.CANCELLED ? <CustomIconCancel /> : <CustomIconConfirmed />),
    [transaction],
  );

  const bookingStart = transactionUuid ? transaction?.details?.start_at : cart?.items[0]?.service_booking?.start_at;
  const hideIcon = useMemo<boolean>(
    () =>
      transactionUuid ? transaction?.details?.status === BookingStatus.PENDING : cart?.items[0]?.status === 'Pending',
    [transactionUuid, transaction, cart],
  );

  const { eventWeekDayLong, eventDateMonthLong, eventDateDay } = formatDate(
    bookingStart,
    locale as string,
    buildingTimeZone,
  );

  const useTransactionObject = Boolean(transactionUuid) || isTransactionCancelled;

  const commonIntlValuesFromTransaction = useMemo(
    () => ({
      serviceName: <BoldText>{transactionDisplay.items[0].display_info.title}</BoldText>,
      serviceTime: <BoldText>{`${eventWeekDayLong}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
    }),
    [transactionDisplay],
  );

  const commonIntlValuesFromCart = useMemo(
    () => ({
      serviceName: <BoldText>{cart?.items[0].display_info.title}</BoldText>,
      serviceTime: <BoldText>{`${eventWeekDayLong}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
    }),
    [transactionDisplay],
  );

  const overviewTitleFromTransaction = useMemo(() => {
    const commonSubtitle = intl.formatMessage({ id: 'timekit_confirmation_text' }, commonIntlValuesFromTransaction);
    const eventSubtitleValues = {
      serviceName: <BoldText>{transaction.details?.service?.name}</BoldText>,
      serviceDate: <BoldText>{`${eventWeekDayLong}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
      serviceTime: (
        <BoldText>
          {getFormattedEventTimeRange(
            transaction.details.start_at,
            transaction.details.end_at,
            locale,
            buildingTimeZone,
          )}
        </BoldText>
      ),
    };

    if (isTransactionCancelled) {
      return {
        title: isEventType && service?.cta_label ? `${service.cta_label.toLowerCase()}_cancelled` : 'booking_cancelled',
        subtitle:
          isEventType && service?.cta_label
            ? intl.formatMessage(
                {
                  id: service?.cta_label
                    ? `event_${service.cta_label.toLowerCase()}_cancelled_text`
                    : 'event_book_cancelled_text',
                },
                eventSubtitleValues,
              )
            : intl.formatMessage({ id: 'timekit_cancelled_text' }, commonIntlValuesFromTransaction),
      };
    }

    if (transaction.type === TRANSACTION_TYPES.SERVICES_BOOKING) {
      if (transaction.details.service.type === TransactionDetailsServiceTypes.APPOINTMENT) {
        return {
          title: 'appointment_booked',
          subtitle: commonSubtitle,
        };
      }

      if (transaction.details.service.type === TransactionDetailsServiceTypes.CLASS) {
        return {
          title: 'class_booked',
          subtitle: commonSubtitle,
        };
      }

      if (transaction.details.service.type === TransactionDetailsServiceTypes.EVENT) {
        return {
          title: service?.cta_label ? `${service?.cta_label.toLowerCase()}_confirmed` : 'rsvp_confirmed',
          subtitle: intl.formatMessage(
            {
              id: service?.cta_label
                ? `event_${service.cta_label.toLowerCase()}_confirmation_text`
                : 'event_book_confirmation_text',
            },
            eventSubtitleValues,
          ),
        };
      }
    }

    if (transaction.type === TRANSACTION_TYPES.MEMBERSHIP) {
      return {
        title: 'membership_activated',
        subtitle: intl.formatMessage(
          { id: 'you_are_member' },
          {
            membership: transactionDisplay.vendor.name,
          },
        ),
      };
    }

    return {
      title: 'default_receipt',
      subtitle: null,
    };
  }, [isTransactionCancelled, transaction, commonIntlValuesFromTransaction]);

  const overviewTitle = useMemo(() => {
    const commonSubtitle = intl.formatMessage({ id: 'timekit_confirmation_text' }, commonIntlValuesFromCart);

    switch (cart?.type) {
      case ORDER_TYPES.SERVICE_BOOKING_EVENT:
        return {
          title: service?.cta_label ? `${service?.cta_label.toLowerCase()}_confirmed` : 'book_confirmed',
          subtitle: intl.formatMessage(
            {
              id: service?.cta_label
                ? `event_${service.cta_label.toLowerCase()}_confirmation_text`
                : 'event_book_confirmation_text',
            },
            {
              serviceName: <BoldText>{cart?.items[0].display_info.title}</BoldText>,
              serviceDate: <BoldText>{`${eventWeekDayLong}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
              serviceTime: (
                <BoldText>
                  {getFormattedEventTimeRange(
                    cart.items[0].service_booking?.start_at,
                    cart.items[0].service_booking?.end_at,
                    locale,
                    buildingTimeZone,
                  )}
                </BoldText>
              ),
            },
          ),
        };
      case ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP:
        return {
          title: 'membership_activated',
          subtitle: intl.formatMessage(
            { id: 'you_are_member' },
            {
              membership: cart?.vendor.name,
            },
          ),
        };
      case ORDER_TYPES.SERVICE_BOOKING_CLASS:
        return {
          title: 'class_booked',
          subtitle: commonSubtitle,
        };
      case ORDER_TYPES.SERVICE_BOOKING_APPOINTMENT:
        return {
          title: 'appointment_booked',
          subtitle: commonSubtitle,
        };
      default:
        return {
          title: 'default_receipt',
          subtitle: null,
        };
    }
  }, [cart]);

  return (
    <OverviewContainer isSwipeModalContent={isSwipeModalContent} data-testid="receipt-overview">
      <TitleContainer>
        <StyledStatusIconTitle
          title={intl.formatMessage({
            id: useTransactionObject ? overviewTitleFromTransaction.title : overviewTitle.title,
          })}
          icon={icon as unknown as React.FC<IconContentProps>}
          hideIcon={hideIcon}
        />
      </TitleContainer>
      <Subtitle>{useTransactionObject ? overviewTitleFromTransaction.subtitle : overviewTitle.subtitle}</Subtitle>
      {!isTransactionCancelled && enableServiceBookingCancellation && isEventType && (
        <CancelButtonContainer>
          <CancelButton
            data-testid="cancel-button"
            variant="Outline"
            text={
              service?.cta_label
                ? intl.formatMessage({ id: `cancel_${service.cta_label.toLowerCase()}` })
                : intl.formatMessage({ id: 'cancel_book' })
            }
            onClick={handleCancelClick}
          />
        </CancelButtonContainer>
      )}
      {(transaction?.type === TRANSACTION_TYPES.MEMBERSHIP ||
        cart?.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP) && (
        <LabelBlock
          title={intl.formatMessage({ id: 'your_plan' })}
          subtitle={
            useTransactionObject ? transactionDisplay.items[0].display_info.title : cart?.items[0].display_info.title
          }
        />
      )}
    </OverviewContainer>
  );
};
