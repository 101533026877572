import {
  selectSelectedAvailableService,
  selectCurrentAvailableService,
  selectCurrentAvailableServiceStatus,
} from 'store/availableServices/selectors';
import {
  ACTION_STATUSES,
  CLASS_CUT_OFF_MONTH_NUMBER,
  SCHEDULE_TYPES,
  TRACK_EVENTS,
  TRACK_EVENT_TYPES,
} from 'shared/consts';
import { Availability, SERVICE_TYPE } from 'store/availableServices/types';
import {
  AvailabilityListWrapper,
  BackLinkWrapper,
  AvailabilityCalendarWrapper,
  HeaderWrapper,
  LoadingContainer,
} from './styles';
import { ItemType, OrderType } from 'store/cart/types';
import React, { useCallback, useEffect, useState } from 'react';
import { isSameOrAfterBaseDateTime, isToday } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import { AvailabilityList } from 'components/availability-list';
import { AvailabilityCalendarTitle } from './availability-calendar-title';
import { AvailableServicesDatePicker } from 'components/available-booking-date-picker';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { createCart, resetCreateCartStatus } from 'store/cart/actions';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { getCurrentAvailableService } from 'store/availableServices/actions';
import moment from 'moment-timezone';
import { goBack, replace } from 'connected-react-router';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { useLocale } from 'hooks/use-locale.hook';
import { track } from '@hqo/web-tracking';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useBackButtonVisibility } from 'hooks/use-back-button-visibility.hook';
import { NavBackLink } from '@hqo/react-components-library/dist/molecules/navBackLink';
import { useIntl } from 'react-intl';
import { selectCartId, selectCreateCartStatus } from 'store/cart/selectors';
import { PaymentIFrame } from 'components/paymentIFrame';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks/use-boolean-state';
import { configSelector } from 'store/config/selectors';
import { SwipePaymentIFrame } from 'components/swipePaymentIFrame';
import { selectCurrentServiceProvider } from 'store/serviceProviders/selectors';
import { ProviderParams } from 'shared/types';
import { PaymentRouter } from 'components/payment-router';
import { useOwnerValues } from 'hooks/use-owner-values.hook';

export const AvailabilityCalendarPage = (): JSX.Element => {
  const timeZone = useTimeZone();
  const locale = useLocale();
  const { path } = useRouteMatch();
  const intl = useIntl();
  const { companyUuid } = useParams<ProviderParams>();
  const { ownerType, ownerUuid } = useOwnerValues();

  const todayDateStartTime = moment.tz(timeZone).startOf('day').toDate();
  const classCutOffDate = moment.tz(timeZone).startOf('day').add(CLASS_CUT_OFF_MONTH_NUMBER, 'months').toDate();
  classCutOffDate.setMonth(classCutOffDate.getMonth() + CLASS_CUT_OFF_MONTH_NUMBER);
  const { buildingUuid } = useSelector(configSelector);
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(todayDateStartTime);
  const [displayEmptyState, setDisplayEmptyState] = useState(false);
  const isDatePastClassCutOffDate = isSameOrAfterBaseDateTime(classCutOffDate, selectedDate);
  const isDateBeforeTodayStartDate = !isSameOrAfterBaseDateTime(todayDateStartTime, selectedDate);
  const selectedAvailableService = useSelector(selectSelectedAvailableService);
  const currentAvailableServicesStatus = useSelector(selectCurrentAvailableServiceStatus);
  const currentAvailable = useSelector(selectCurrentAvailableService);
  const createCartStatus = useSelector(selectCreateCartStatus);
  const cartId = useSelector(selectCartId);
  const { serviceBookingLocalTimeUpdate: inLocalTimeLD, enableServiceBookingPaymentsMigration } = useFlags();
  const { value: reverseAnimation, toggle: toggleReverseAnimation } = useBooleanState(false);
  const isBackButtonVisible = useBackButtonVisibility();
  const currentServiceProvider = useSelector(selectCurrentServiceProvider);

  const onPressBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  useEffect(() => {
    if (isDatePastClassCutOffDate || isDateBeforeTodayStartDate) {
      setDisplayEmptyState(true);
    } else {
      setDisplayEmptyState(false);
      const todayDateTime = inLocalTimeLD
        ? moment.tz(timeZone).utcOffset(0, true).startOf('day').toDate()
        : moment.tz(timeZone).startOf('day').toDate();
      const selectedDateTime = inLocalTimeLD ? moment(selectedDate).utcOffset(0).startOf('day') : selectedDate;

      // If date is same as today date, make the updatedDateTime have the current time.
      const updatedDateTime = isToday(selectedDate, locale, timeZone) ? todayDateTime : selectedDateTime;

      const availabilityStart = inLocalTimeLD
        ? moment(updatedDateTime).toISOString()
        : moment(updatedDateTime).tz(timeZone).toISOString();
      const availabilityEnd = inLocalTimeLD
        ? moment(updatedDateTime).utcOffset(0).endOf('day').toISOString()
        : moment(updatedDateTime).tz(timeZone).endOf('day').toISOString();

      dispatch(
        getCurrentAvailableService.request({
          ownerType,
          ownerUuid,
          companyUuid,
          serviceUuid: selectedAvailableService?.uuid,
          availabilityStart,
          availabilityEnd,
          serviceTypeId: selectedAvailableService?.service_type_id?.toString(),
        }),
      );
    }
  }, [
    selectedDate,
    dispatch,
    isDateBeforeTodayStartDate,
    isDatePastClassCutOffDate,
    displayEmptyState,
    selectedAvailableService,
    timeZone,
    buildingUuid,
    ownerUuid,
    ownerType,
    companyUuid,
  ]);

  useEffect(() => {
    track(
      TRACK_EVENTS.SCHEDULE_IMPRESSION,
      {
        type: TRACK_EVENT_TYPES.IMPRESSION,
        schedule_type: SCHEDULE_TYPES.APPOINTMENT,
      },
      { sendToPendo: true, sendToHqoTracking: false },
    );
  }, []);

  useEffect(() => {
    if (createCartStatus === ACTION_STATUSES.REJECTED) {
      dispatch(resetCreateCartStatus());
    }
    if (createCartStatus === ACTION_STATUSES.FULFILLED && !enableServiceBookingPaymentsMigration) {
      const internalPath = `${location.pathname}/payment/${cartId}?quickCheckout=true`;
      dispatch(resetCreateCartStatus());
      dispatch(replace(internalPath));
    }
  }, [dispatch, createCartStatus, cartId, enableServiceBookingPaymentsMigration]);

  const handleServiceBookClick = useCallback(
    (service: Availability) => {
      dispatch(
        createCart.request({
          type: OrderType.SERVICE_BOOKING_APPOINTMENT,
          owner_type: currentServiceProvider?.owner_type,
          owner_id: currentServiceProvider?.owner_uuid,
          building_uuid: buildingUuid,
          app_instance_config_uuid: currentServiceProvider?.app_instance_config_uuid,
          items: [
            {
              id: `${selectedAvailableService?.id}`,
              type: ItemType.SERVICE_BOOKING_APPOINTMENT,
              quantity: 1,
              price: selectedAvailableService?.price,
              display_info: {
                title: selectedAvailableService?.admin_name,
                description1: service?.resource.name,
                description2: inLocalTimeLD
                  ? moment(service?.start_at).tz(timeZone, true).toISOString()
                  : service?.start_at,
              },
              service_booking: {
                resource_uuid: service?.resource_uuid.toString(),
                start_at: inLocalTimeLD
                  ? moment(service?.start_at).tz(timeZone, true).toISOString()
                  : service?.start_at,
                end_at: inLocalTimeLD ? moment(service?.end_at).tz(timeZone, true).toISOString() : service?.end_at,
                service_type_id: selectedAvailableService?.service_type_id?.toString(),
              },
            },
          ],
          booking_type: SERVICE_TYPE.APPOINTMENT,
        }),
      );
    },
    [dispatch, currentServiceProvider, buildingUuid, selectedAvailableService],
  );

  return (
    <AvailabilityCalendarWrapper data-testid="availability-calendar-wrapper">
      <HeaderWrapper>
        {isBackButtonVisible && (
          <BackLinkWrapper>
            <NavBackLink
              dataTestId="availability-calendar-back-link"
              onClick={onPressBack}
              backLinkText={intl.formatMessage({ id: 'back' })}
            />
          </BackLinkWrapper>
        )}
        <AvailabilityCalendarTitle />
        <AvailableServicesDatePicker
          cutOffFutureDate={classCutOffDate}
          handleDateChange={setSelectedDate}
          selectedDate={selectedDate}
        />
      </HeaderWrapper>
      {currentAvailableServicesStatus === ACTION_STATUSES.PENDING && (
        <LoadingContainer>
          <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
        </LoadingContainer>
      )}
      {(displayEmptyState || currentAvailableServicesStatus === ACTION_STATUSES.FULFILLED) && (
        <AvailabilityListWrapper isBackButtonVisible={isBackButtonVisible}>
          <AvailabilityList
            onActionButtonClick={handleServiceBookClick}
            isEmpty={displayEmptyState || !currentAvailable?.availabilities?.length}
          />
        </AvailabilityListWrapper>
      )}
      {enableServiceBookingPaymentsMigration && <PaymentRouter />}
      <Switch>
        <Route path={`${path}/swipe-payment/:cartId`}>
          <SwipePaymentIFrame
            reverseAnimation={reverseAnimation}
            currentOrderType={OrderType.SERVICE_BOOKING_APPOINTMENT}
          />
        </Route>

        <Route path={`${path}/payment/:cartId`}>
          <PaymentIFrame
            currentOrderType={OrderType.SERVICE_BOOKING_APPOINTMENT}
            reverseAnimation={reverseAnimation}
            toggleReverseAnimation={toggleReverseAnimation}
          />
        </Route>
      </Switch>
    </AvailabilityCalendarWrapper>
  );
};
