import { DEVICE_WIDTHS } from 'shared/consts';
import { DIMENSIONS } from '@hqo/react-components-library';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Content = styled.div`
  height: calc(100vh - 240px);
`;

export const ToggleWrapper = styled.div`
  max-width: 340px;
  padding: 0 15px;
  text-align: center;
  margin: auto;
  margin-bottom: 50px;

  .active {
    font-weight: 500;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-bottom: 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 55px;
  box-shadow: 0px -2px 13px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    background: ${({ theme }) => theme.colors.$white};
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 30px);
    height: 48px;
    padding: 10px 15px;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  @media only screen and (max-width: ${DEVICE_WIDTHS.SMALL}px) {
    height: 100%;
    width: 100%;
    margin: 10px 8px 12px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
`;

export const AppointmentsContentWrapper = styled.div<{ isBackButtonVisible: boolean }>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 126px);

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: ${({ isBackButtonVisible }) => (isBackButtonVisible ? 'calc(100vh - 135px)' : 'calc(100vh - 104px)')};
  } ;
`;

export const ScheduleListWrapper = styled.div<{ isBackButtonVisible: boolean }>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 237px);

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 0 16px;
    height: ${({ isBackButtonVisible }) => (isBackButtonVisible ? 'calc(100vh - 246px)' : 'calc(100vh - 214px)')};
  }
`;
