import { ButtonContainer, ContentContainer, ModalContainer, TitleContainer } from './styles';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';
import { ACTION_STATUSES } from 'shared/consts';
import { Container } from 'hocs/shared-styles';
import { PaymentMethods } from 'components/payment-methods';
import React, { useMemo } from 'react';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { useIntl } from 'react-intl';
import { CheckoutButton } from 'components/checkout-button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Cart } from 'components/cart';
import { CheckoutMobileProps } from '../types';
import { useSelector } from 'react-redux';
import { selectSubmitCartStatus } from 'store/cart/selectors';

export const CheckoutMobile = ({
  cart,
  onAddPaymentMethodClick,
  paymentMethods,
  currentPaymentMethodId,
  submittedPaymentMethodId,
  onRemovePaymentMethod,
  onPayClick,
  isCartFulfilled,
  showAddCardForm,
  currentPaymentError,
  cartId,
  onCancelAddCardClick,
}: CheckoutMobileProps): JSX.Element => {
  const intl = useIntl();
  const { total } = formatSummaryValues(cart?.total_summary);
  const { showMiniappPaymentsNavigation } = useFlags();
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const buttonText = useMemo<string>(
    () =>
      cart?.total_summary?.total
        ? intl.formatMessage({ id: 'confirm_and_pay' })
        : intl.formatMessage({ id: 'confirm_and_book' }),
    [cart?.total_summary?.total],
  );
  const isDisabledButton = useMemo<boolean>(
    () => !currentPaymentMethodId || submitCartStatus === ACTION_STATUSES.PENDING || showAddCardForm,
    [currentPaymentMethodId, submitCartStatus, showAddCardForm],
  );

  return (
    <ModalContainer>
      {!cart || !paymentMethods ? (
        <Container isLoading>
          <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
        </Container>
      ) : (
        <ContentContainer>
          <TitleContainer>
            <TitleBlock title={intl.formatMessage({ id: 'checkout' })} />
          </TitleContainer>
          {isCartFulfilled && <Cart cart={cart} isCheckoutView isSwipeModalContent={showMiniappPaymentsNavigation} />}
          <PaymentMethods
            cart={cart}
            showAddCardForm={showAddCardForm}
            onCancelAddCardClick={onCancelAddCardClick}
            isSwipeModalContent={showMiniappPaymentsNavigation}
            enabledPaymentMethods={cart.ui_enablements}
            paymentMethods={paymentMethods}
            selectedPaymentMethodId={currentPaymentMethodId}
            submittedPaymentMethodId={submittedPaymentMethodId}
            onRemovePaymentMethod={onRemovePaymentMethod}
            onAddNewCard={onAddPaymentMethodClick}
            currentPaymentError={currentPaymentError}
            cartId={cartId}
            total={total}
            formTitle={intl.formatMessage({ id: 'payment' })}
          />
          <ButtonContainer>
            <CheckoutButton
              text={buttonText}
              isDisabled={isDisabledButton}
              isLoading={submitCartStatus === ACTION_STATUSES.PENDING}
              onClick={onPayClick}
            />
          </ButtonContainer>
        </ContentContainer>
      )}
    </ModalContainer>
  );
};
