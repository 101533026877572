import { TRACK_EVENTS, TRACK_EVENT_TYPES } from 'shared/consts';
import { Subtitle, Title, UpcomingBookingContainer } from './styles';
import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import React, { useCallback, useRef } from 'react';
import { formatDateTitle, formatEventDuration } from 'utils';
import { useDispatch, useSelector } from 'react-redux';

import { orientationEnum } from '../upcoming-bookings.interface';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { getTransaction } from 'store/transactions/actions';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { track } from '@hqo/web-tracking';
import { TransactionDetails } from 'store/transactions/types';
import { replace } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface UpcomingBookingProps {
  transaction: TransactionDetails;
}

export const UpcomingBooking = ({ transaction }: UpcomingBookingProps): JSX.Element => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const timeZone = useSelector(selectBuildingTimeZone);
  const locale = useLocale();
  const isMobileDevice = useIsSmallViewportWidth();
  const orientation = isMobileDevice ? orientationEnum.horizontal : orientationEnum.vertical;
  const { enableServiceBookingCancellation } = useFlags();

  const { details, uuid } = transaction || {};
  const { service, start_at, end_at } = details || {};
  const containerRef = useRef(null);

  const onUpcomingBookingClick = useCallback(() => {
    if (enableServiceBookingCancellation) {
      dispatch(getTransaction.request(uuid));
      dispatch(replace(`${pathname}/receipt?transactionUuid=${uuid}`));
    }

    track(
      TRACK_EVENTS.UPCOMING_BOOKING_CLICK,
      { type: TRACK_EVENT_TYPES.ACTION },
      { sendToPendo: false, sendToHqoTracking: true },
    );
  }, [uuid, dispatch, pathname, enableServiceBookingCancellation, track, containerRef]);

  return (
    <UpcomingBookingContainer orientation={orientation} onClick={onUpcomingBookingClick} ref={containerRef}>
      <Title>{service.name}</Title>
      <Subtitle>{service.resource_name && `${intl.formatMessage({ id: 'with' })} ${service.resource_name}`}</Subtitle>
      <IconBlock
        key={uuid}
        title={formatDateTitle(new Date(start_at), intl.formatMessage({ id: 'today' }), locale, timeZone)}
        icon="calendar-alt"
        iconType="fal"
        onClick={onUpcomingBookingClick}
        subtitle={`${formatEventDuration(start_at, end_at, locale, timeZone).eventStartTime} / ${
          service.duration
        } ${intl.formatMessage({ id: 'min' })}`}
      />
    </UpcomingBookingContainer>
  );
};
