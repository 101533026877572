import { ACTION_STATUSES, FontLocation, ROOT_PATH } from 'shared/consts';
import { Container, PageHeaderStyled } from './styles';
import React, { useCallback, useEffect } from 'react';
import { selectServiceProvidersListView, selectServiceProvidersStatus } from 'store/serviceProviders/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { EmptyProvidersPlaceholder } from 'components/providers-list/empty-providers-placeholder';
import { InfiniteLoadingList } from 'components/providers-list/infinite-loading-list';
import { List } from 'components/providers-list/list';
import { PageTitle } from '@hqo/react-components-library';
import { getServiceProviders } from 'store/serviceProviders/actions';
import { push } from 'connected-react-router';
import { useIntl } from 'react-intl';
import { useThemeFont } from 'hooks/use-theme-font.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PaymentRouter } from 'components/payment-router';
import { OwnerType } from 'shared/consts/consts';
import { configSelector } from 'store/config/selectors';

export const ServiceProvidersPage = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const providersStatus = useSelector(selectServiceProvidersStatus);
  const { buildingUuid } = useSelector(configSelector);
  const themeFontTitle = useThemeFont(FontLocation.TITLE);
  const { enableServiceBookingPaymentsMigration } = useFlags();

  const providers = useSelector(selectServiceProvidersListView);

  useEffect(() => {
    dispatch(getServiceProviders.request({ ownerType: OwnerType.BUILDING, ownerUuid: buildingUuid }));
  }, [buildingUuid]);

  const handleContentClick = useCallback(companyUuid => {
    dispatch(push(`${ROOT_PATH}/${companyUuid}`));
  }, []);

  const loading = providersStatus === null || providersStatus === ACTION_STATUSES.PENDING;

  return (
    <Container>
      <PageHeaderStyled variant="transparent">
        <PageTitle font={themeFontTitle}>{intl.formatMessage({ id: 'service_providers' })}</PageTitle>
      </PageHeaderStyled>
      {loading && <InfiniteLoadingList />}
      {!loading && providers.length > 0 && <List providers={providers} handleProviderClick={handleContentClick} />}
      {!loading && providers.length === 0 && <EmptyProvidersPlaceholder />}
      {enableServiceBookingPaymentsMigration && <PaymentRouter />}
    </Container>
  );
};
