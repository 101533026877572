import React, { useEffect, useMemo } from 'react';

import { Checkout } from 'components/payment/checkout';
import { Receipt } from 'components/payment/receipt';
import { useCheckoutScreen } from 'hooks/payment-content/use-checkout-screen.hook';
import { useQuickCheckout } from 'hooks/payment-content/use-quick-checkout.hook';
import { useReceiptScreen } from 'hooks/payment-content/use-receipt-screen.hook';
import { useMiniappSdk } from 'hooks/use-miniapp-sdk.hook';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';

import { PaymentModalHandler } from '../payment-modal-handler';
import { QuickCheckout } from '../payment/quick-checkout/quick-checkout';
import { CHECKOUT_PATH, QUICK_CHECKOUT_PATH, RECEIPT_PATH } from 'shared/consts';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectTransaction } from 'store/transactions/selectors';
import { getTransaction } from 'store/transactions/actions';

export const PaymentRouter = (): JSX.Element => {
  const dispatch = useDispatch();
  const isMobileDevice = useIsSmallViewportWidth();
  const client = useMiniappSdk();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const { closeQuickCheckoutHandler } = useQuickCheckout();
  const { onCloseCheckout } = useCheckoutScreen();
  const { onCloseReceipt, onPressBackReceipt, withLeftArrowButtonOnTheReceipt } = useReceiptScreen();
  const hideHeader = useMemo<boolean>(
    () => (pathname.includes('checkout') || pathname.includes('receipt')) && isMobileDevice,
    [pathname, isMobileDevice],
  );
  const { transactionUuid } = useSearchParams();
  const transaction = useSelector(selectTransaction);

  useEffect(() => {
    if (hideHeader) {
      client?.header.hideHeader();
      client?.swipe.disableBackSwipe();
    }

    return () => {
      client?.header.showHeader();
      client?.swipe.enableBackSwipe();
    };
  }, [client, isMobileDevice, hideHeader]);

  useEffect(() => {
    if (!transaction && transactionUuid && pathname.includes(RECEIPT_PATH)) {
      dispatch(getTransaction.request(transactionUuid as string));
    }
  }, [dispatch, transaction, transactionUuid]);

  return (
    <>
      <Switch>
        <Route path={`${path}${QUICK_CHECKOUT_PATH}`}>
          <QuickCheckout closeQuickCheckoutHandler={closeQuickCheckoutHandler} reverseAnimation={false} />
        </Route>
        <Route path={`${path}${CHECKOUT_PATH}`}>
          <PaymentModalHandler content={<Checkout onCloseCheckout={onCloseCheckout} />} onClose={onCloseCheckout} />
        </Route>
        <Route path={`${path}${RECEIPT_PATH}`}>
          <PaymentModalHandler
            content={<Receipt onCloseReceipt={onCloseReceipt} />}
            onClose={onCloseReceipt}
            onPressBack={onPressBackReceipt}
            withLeftArrowButton={withLeftArrowButtonOnTheReceipt}
          />
        </Route>
      </Switch>
    </>
  );
};
