import * as availableServicesActions from 'store/availableServices/actions';
import * as buildingActions from 'store/building/actions';
import * as cartActions from 'store/cart/actions';
import * as serviceMembershipsActions from 'store/serviceMemberships/actions';
import * as serviceProvidersActions from 'store/serviceProviders/actions';
import * as serviceRegistrationActions from 'store/serviceRegistration/actions';
import * as themeActions from 'store/theme/actions';
import * as userActions from 'store/user/actions';

import { concatMap, filter, map } from 'rxjs/operators';
import { showErrorNotification, showGenericErrorNotification } from './actions';

import { ERROR_PATH } from 'shared/consts';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { replace } from 'connected-react-router';

export const handleFailedRequests: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    // Add failure actions to trigger error modal here and in the test file
    filter(isActionOf([serviceRegistrationActions.registerUser.failure, cartActions.createCart.failure])),
    map(action =>
      showErrorNotification({
        action: action.type,
        error: action.payload.error,
      }),
    ),
  );

export const handleGenericErrors: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    // Add failure actions to trigger error page redirects here and in the test file
    filter(
      isActionOf([
        buildingActions.getBuilding.failure,
        themeActions.getBuildingTheme.failure,
        userActions.externalLogin.failure,
        userActions.getCurrentUser.failure,
        serviceProvidersActions.getServiceProviders.failure,
        serviceProvidersActions.getCurrentServiceProvider.failure,
        serviceMembershipsActions.getMemberships.failure,
        serviceRegistrationActions.getRegistrationFields.failure,
        availableServicesActions.getCurrentAvailableService.failure,
        availableServicesActions.getAvailableServices.failure,
        cartActions.setScreenshotAction.failure,
      ]),
    ),
    concatMap(action =>
      of(
        showGenericErrorNotification({
          action: action.type,
          error: action.payload.error,
        }),
        replace(ERROR_PATH),
      ),
    ),
  );
