import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCart, selectCartId, selectCreateCartStatus, selectSubmitCartStatus } from 'store/cart/selectors';
import { createCart, resetCreateCartStatus, resetSubmitCartStatus, submitCart } from 'store/cart/actions';
import { ACTION_STATUSES, OwnerType } from 'shared/consts';
import { ItemType, OrderType } from 'store/cart/types';
import { Service, SERVICE_TYPE } from 'store/availableServices/types';
import { useLocale } from 'hooks/use-locale.hook';
import { selectBuilding } from 'store/building/selectors';
import { getTransaction } from 'store/transactions/actions';
import { selectGetTransactionStatus } from 'store/transactions/selectors';

interface UseCtaButtonReturnValues {
  handleClick: VoidFunction;
  isButtonLoadingAndDisabled: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const useCtaButton = (service: Service): UseCtaButtonReturnValues => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const building = useSelector(selectBuilding);
  const cartId = useSelector(selectCartId);
  const createCartStatus = useSelector(selectCreateCartStatus);
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const getTransactionStatus = useSelector(selectGetTransactionStatus);
  const cart = useSelector(selectCart);

  const isButtonLoadingAndDisabled = useMemo<boolean>(
    () =>
      createCartStatus === ACTION_STATUSES.PENDING ||
      submitCartStatus === ACTION_STATUSES.PENDING ||
      getTransactionStatus === ACTION_STATUSES.PENDING,
    [createCartStatus, submitCartStatus, getTransactionStatus],
  );

  const createCartPayload = useMemo(
    () => ({
      type: OrderType.SERVICE_BOOKING_EVENT,
      owner_type: OwnerType.BUILDING,
      owner_id: building.uuid,
      building_uuid: building.uuid,
      items: [
        {
          id: service.uuid,
          type: ItemType.SERVICE_BOOKING_EVENT,
          quantity: 1,
          price: service.price,
          display_order: 1,
          display_info: {
            lang: locale,
            title: service.title,
            subtitle: service.subtitle,
            description1: service.description,
          },
          service_booking: {
            start_at: service.start_at,
            end_at: service.end_at,
          },
        },
      ],
      booking_type: SERVICE_TYPE.GROUP,
    }),
    [service, locale, building],
  );

  useEffect(() => {
    if (cartId && createCartStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(submitCart.request({ cart_id: cartId }));
      dispatch(resetCreateCartStatus());
    }
  }, [cartId, dispatch, createCartStatus]);

  useEffect(() => {
    if (cart && submitCartStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getTransaction.request(cart.transaction_uuid));
      dispatch(resetSubmitCartStatus());
    }
  }, [cart, dispatch, submitCartStatus]);

  const handleClick = useCallback(() => {
    dispatch(createCart.request(createCartPayload));
  }, [dispatch, createCartPayload]);

  return { handleClick, isButtonLoadingAndDisabled };
};
