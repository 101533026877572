import {
  ACTION_STATUSES,
  IMG_URL,
  TRACK_EVENT_TYPES,
  TRACK_EVENTS,
  PROVIDER_BASE_PATH,
  ROOT_PATH,
} from 'shared/consts';
import {
  BecomeMemberButtonWrapper,
  ContactUsButtonContainer,
  Content,
  IconBlockContainer,
  InfoText,
  MainContainer,
  ProviderContainer,
  Section,
  SectionHeader,
  ServiceAvailabilityButton,
  ServiceAvailabilityButtonWrapper,
  ServicesContainer,
  StyledButton,
  StyledImage,
  StyledLink,
  StyledLinkBecomeMember,
  Subtitle,
  Title,
} from 'pages/service-provider/styles';
import { ButtonBlock, useBooleanState } from '@hqo/react-components-library';
import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';
import { LoadingSkeleton } from '@hqo/react-components-library/dist/molecules/loading-skeleton';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { IntlShape, useIntl } from 'react-intl';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, matchPath, useParams, useRouteMatch } from 'react-router-dom';
import { getCurrentServiceProvider, resetCurrentServiceProviderStatus } from 'store/serviceProviders/actions';
import {
  selectCurrentServiceProvider,
  selectCurrentServiceProviderDetailView,
  selectCurrentServiceProviderStatus,
  selectFormattedUserContracts,
  selectIsMembershipSignupEnabled,
  selectIsQRCodeEnabled,
  selectIsRegistrationEnabled,
  selectIsUserRegistered,
  selectServiceProviderAdapter,
  selectServiceProvidersStatus,
  selectUserMemberships,
} from 'store/serviceProviders/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { LinkoutBlock } from './components';
import { Membership, MembershipStatusEnum } from 'store/serviceMemberships/types';
import { OrderType } from 'store/cart/types';
import { PaymentIFrame } from 'components/paymentIFrame';
import { ProviderParams } from 'shared/types';
import { OwnerType, SCHEDULE_TYPES_PARAMS, SERVICE_PROVIDER_ADAPTERS } from 'shared/consts/consts';
import { ServiceProviderDetailView } from 'store/serviceProviders/types';
import { UpcomingBookings } from 'components/upcoming-bookings';
import { configSelector } from 'store/config/selectors';
import { push, replace } from 'connected-react-router';
import { resetRegisterUserStatus } from 'store/serviceRegistration/actions';
import { selectRegisterUserStatus } from 'store/serviceRegistration/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { track } from '@hqo/web-tracking';
import { RegisterContractAgreementModal } from 'pages/register-contract-agreement-modal/register-contract-agreement-modal';
import { RegistrationContractAgreementStep } from 'shared/consts/service-provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SwipePaymentIFrame } from 'components/swipePaymentIFrame';
import { PaymentRouter } from 'components/payment-router';
import { useV3CompanyUuid } from 'hooks/use-v3-company-uuid.hook';

export interface ProviderProps {
  orderType?: OrderType;
}

const renderServiceProviderDescription = (providerDetails: ServiceProviderDetailView, header: string) =>
  providerDetails.description ? (
    <>
      <SectionHeader>{header}</SectionHeader>
      <Content
        hasInfo={!!providerDetails.userCount}
        dangerouslySetInnerHTML={{ __html: `${providerDetails.description.replace(/\n/g, '<br/>')}` }}
      />
    </>
  ) : null;

const renderServiceProviderBookingCount = (providerDetails: ServiceProviderDetailView, bookedText: string) =>
  providerDetails.userCount ? <InfoText>{bookedText}</InfoText> : null;

const renderServiceProviderAddress = (providerDetails: ServiceProviderDetailView, linkText: string) =>
  providerDetails.address ? (
    <IconBlockContainer>
      <IconBlock
        icon="map-marker-alt"
        iconType="fal"
        linkRef={`https://www.google.com/maps?q=${encodeURI(providerDetails.address)}`}
        linkText={linkText}
        subtitle={providerDetails.directions}
        title={providerDetails.address}
        shouldOpenInNewTab={!/mobi/i.test(navigator.userAgent)}
      />
    </IconBlockContainer>
  ) : null;

const renderServiceProviderPhone = (phone: string) =>
  phone ? (
    <IconBlockContainer>
      <IconBlock icon="phone" iconType="fal" isPhoneNumber linkRef={phone} linkText={phone} title="" />
    </IconBlockContainer>
  ) : null;

const renderMemberButton = (showButton: boolean, handleMembershipRedirect: () => void, intl: IntlShape) =>
  showButton && (
    <BecomeMemberButtonWrapper>
      <StyledButton
        data-testid="become-member"
        variant="secondary"
        text={intl.formatMessage({ id: 'become_member' })}
        onClick={handleMembershipRedirect}
      />
    </BecomeMemberButtonWrapper>
  );

const renderGymCardButton = (
  showGymCardButton: boolean,
  gymCardRedirect: string,
  intl: IntlShape,
  trackCheckInClick: () => void,
) =>
  showGymCardButton && (
    <BecomeMemberButtonWrapper>
      <StyledLinkBecomeMember to={gymCardRedirect} onClick={trackCheckInClick}>
        <StyledButton
          data-testid="access-gym-card"
          variant="secondary"
          text={intl.formatMessage({ id: 'access_gym_card' })}
          Icon={<FontAwesomeIcon icon="qrcode" />}
          iconDirection="left"
        />
      </StyledLinkBecomeMember>
    </BecomeMemberButtonWrapper>
  );

export const ServiceProviderPage = ({ orderType }: ProviderProps): JSX.Element => {
  const intl = useIntl();
  const { companyUuid } = useParams<ProviderParams>();
  const { url, path } = useRouteMatch();

  const { value: reverseAnimation, toggle: toggleReverseAnimation } = useBooleanState(false);
  const [showMembershipButton, setShowMembershipButton] = useState<boolean>(true);
  const [showGymCardButton, setShowGymCardButton] = useState<boolean>(true);
  const [isReadyToTrack, setIsReadyToTrack] = useState<boolean>(false);
  const [showMembershipsModal, setShowMembershipsModal] = useState<boolean>(false);
  const [currentOrderType, setCurrentOrderType] = useState<OrderType>(orderType);
  const {
    serviceBookingScanQrCode: isQRCodeEnabledLD,
    serviceBookingViewSchedule: isViewScheduleEnabledLD,
    serviceBookingMembershipRegistration: isMembershipEnabledLD,
    enableServiceBookingPaymentsMigration,
  } = useFlags();
  const providerDetails = useSelector(selectCurrentServiceProviderDetailView);
  const getCurrentProviderStatus = useSelector(selectCurrentServiceProviderStatus);
  const registerUserStatus = useSelector(selectRegisterUserStatus);
  const currentServiceProvider = useSelector(selectCurrentServiceProvider);
  const isUserRegistered = useSelector(selectIsUserRegistered);
  const isMembershipSignupEnabled = useSelector(selectIsMembershipSignupEnabled);
  const userMemberships = useSelector(selectUserMemberships);
  const formattedUserMemberships = useSelector(selectFormattedUserContracts);
  const serviceProviderAdapter = useSelector(selectServiceProviderAdapter);
  const isRegistrationEnabled = useSelector(selectIsRegistrationEnabled);
  const isQRCodeEnabled = useSelector(selectIsQRCodeEnabled);
  const { buildingUuid } = useSelector(configSelector);
  const isMobileDevice = useIsSmallViewportWidth();
  const isProviderUrl = url.endsWith(companyUuid);
  const dispatch = useDispatch();
  const isMBO = serviceProviderAdapter === SERVICE_PROVIDER_ADAPTERS.MINDBODY;
  const getServiceProvidersStatus = useSelector(selectServiceProvidersStatus);
  useV3CompanyUuid();

  useEffect(() => {
    return () => {
      dispatch(resetCurrentServiceProviderStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (getServiceProvidersStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(
        getCurrentServiceProvider.request({
          ownerUuid: buildingUuid,
          ownerType: OwnerType.BUILDING,
          companyUuid,
        }),
      );
    }
  }, [dispatch, companyUuid, getServiceProvidersStatus]);

  const serviceAvailabilityButtonUrl = providerDetails.isClassesEnable
    ? `schedule/${SCHEDULE_TYPES_PARAMS.CLASSES}`
    : `schedule/${SCHEDULE_TYPES_PARAMS.APPOINTMENTS}`;

  useEffect(() => {
    if (registerUserStatus === ACTION_STATUSES.REJECTED) {
      dispatch(resetRegisterUserStatus());
    }
    if (registerUserStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(resetRegisterUserStatus());
      if (!isMembershipSignupEnabled) {
        dispatch(replace(`${url.replace(/\/register-contract/, '')}`));
      }
    }
  }, [registerUserStatus, isMembershipSignupEnabled, url, currentServiceProvider]);

  const hasMembershipsWithDesiredStatuses = useCallback(
    (statuses: Array<MembershipStatusEnum>) => {
      return !!userMemberships?.find((membership: Membership) => statuses?.includes(membership?.status));
    },
    [userMemberships],
  );

  useEffect(() => {
    setShowMembershipsModal(
      currentServiceProvider &&
        isUserRegistered &&
        isMembershipSignupEnabled &&
        (!userMemberships?.length || hasMembershipsWithDesiredStatuses([MembershipStatusEnum.SUSPENDED])) &&
        isMBO,
    );
  }, [
    currentServiceProvider,
    hasMembershipsWithDesiredStatuses,
    isMBO,
    isMembershipSignupEnabled,
    isUserRegistered,
    userMemberships,
  ]);

  useEffect(() => {
    if (isUserRegistered && !isMembershipSignupEnabled && url.includes('/register-contract')) {
      dispatch(replace(`${url.split('/register-contract')[0]}`));
    }
  }, [isUserRegistered, registerUserStatus, dispatch, url, isMembershipSignupEnabled]);

  useEffect(() => {
    if (getCurrentProviderStatus === ACTION_STATUSES.FULFILLED) {
      setShowMembershipButton(
        isMembershipEnabledLD &&
          isMBO &&
          ((isUserRegistered &&
            !hasMembershipsWithDesiredStatuses([MembershipStatusEnum.ACTIVE, MembershipStatusEnum.INACTIVE]) &&
            isMembershipSignupEnabled) ||
            (!isUserRegistered && isRegistrationEnabled)),
      );

      setShowGymCardButton(
        isQRCodeEnabledLD &&
          isMobileDevice &&
          isMBO &&
          isUserRegistered &&
          isQRCodeEnabled &&
          hasMembershipsWithDesiredStatuses([MembershipStatusEnum.ACTIVE]),
      );
      setIsReadyToTrack(true);
    }
  }, [currentServiceProvider]);

  useEffect(() => {
    if (!showMembershipsModal) {
      document.body.style.overflow = 'initial';
    }
  }, [showMembershipsModal]);

  const handleGoBack = useCallback(() => {
    const match = matchPath(url, {
      path: PROVIDER_BASE_PATH,
      exact: true,
    });
    if (match?.isExact) {
      dispatch(push(`${ROOT_PATH}`));
    }
  }, [url, buildingUuid]);

  useEffect(() => {
    window.addEventListener('popstate', handleGoBack);
    return () => {
      window.removeEventListener('popstate', handleGoBack);
    };
  }, [handleGoBack]);

  const handleMembershipRedirect = useCallback(() => {
    track(
      TRACK_EVENTS.NEW_CONTRACT_CLICK,
      { type: TRACK_EVENT_TYPES.ACTION },
      { sendToPendo: true, sendToHqoTracking: false },
    );
    dispatch(replace(`${url}/register-contract?step=${RegistrationContractAgreementStep.ENROLLMENT}`));
  }, [dispatch, url]);

  const handleContactUsOnClick = useCallback(() => {
    window.open(`mailto:${providerDetails.managementEmail || ''}`, '_blank');
  }, [currentServiceProvider]);

  const getVisibleCTAButtonsToTrack = useCallback(() => {
    const buttons = [intl.formatMessage({ id: 'view_schedule' })];
    if (showMembershipButton) {
      buttons.push(intl.formatMessage({ id: 'become_member' }));
    }
    if (showGymCardButton) {
      buttons.push(intl.formatMessage({ id: 'access_gym_card' }));
    }
    return buttons;
  }, [intl, showGymCardButton, showMembershipButton]);

  useEffect(() => {
    if (getCurrentProviderStatus === ACTION_STATUSES.FULFILLED && isProviderUrl && isReadyToTrack) {
      track(
        TRACK_EVENTS.VENDOR_DETAIL_IMPRESSION,
        {
          type: TRACK_EVENT_TYPES.IMPRESSION,
          button_cta: getVisibleCTAButtonsToTrack(),
          adapter: serviceProviderAdapter,
          vendor_name: currentServiceProvider?.name,
          is_registered: isUserRegistered,
          user_contracts: formattedUserMemberships,
        },
        { sendToPendo: true, sendToHqoTracking: true },
      );
      setIsReadyToTrack(false);
    }
  }, [
    getCurrentProviderStatus,
    isUserRegistered,
    formattedUserMemberships,
    currentServiceProvider,
    getVisibleCTAButtonsToTrack,
    serviceProviderAdapter,
    isReadyToTrack,
    isProviderUrl,
  ]);

  const trackViewScheduleClick = useCallback(() => {
    track(
      TRACK_EVENTS.VIEW_SCHEDULE_CLICK,
      { type: TRACK_EVENT_TYPES.ACTION },
      { sendToPendo: true, sendToHqoTracking: false },
    );
  }, []);

  const trackCheckInClick = (): void => {
    track(
      TRACK_EVENTS.CHECK_IN_CLICK,
      { type: TRACK_EVENT_TYPES.ACTION },
      { sendToPendo: true, sendToHqoTracking: false },
    );
  };

  return (getCurrentProviderStatus !== ACTION_STATUSES.PENDING && getCurrentProviderStatus !== null) ||
    url.includes('register-contract') ? (
    <MainContainer>
      <ProviderContainer>
        <StyledImage alt="" src={IMG_URL + providerDetails.imageUrl} />
        <Title>{providerDetails.title}</Title>
        <Subtitle>{providerDetails.subtitle}</Subtitle>
        {renderServiceProviderAddress(providerDetails, intl.formatMessage({ id: 'open_in_maps' }))}
        {renderServiceProviderPhone(providerDetails.phone)}
        {currentServiceProvider && <LinkoutBlock serviceProviderConfig={currentServiceProvider.config} />}
        {renderMemberButton(showMembershipButton, handleMembershipRedirect, intl)}
        {renderGymCardButton(showGymCardButton, `${url}/gymcard`, intl, trackCheckInClick)}
        <Section>
          {renderServiceProviderDescription(providerDetails, intl.formatMessage({ id: 'description' }))}
          {renderServiceProviderBookingCount(
            providerDetails,
            providerDetails.userCount === 1
              ? intl.formatMessage({ id: 'person_has_booked' })
              : intl.formatMessage({ id: 'people_have_booked' }, { userCount: providerDetails.userCount }),
          )}
        </Section>
        {providerDetails.managementEmail && (
          <ContactUsButtonContainer data-testid="contact-us">
            <ButtonBlock
              text={intl.formatMessage({ id: 'questions' })}
              buttonLabel={intl.formatMessage({ id: 'contact_us' })}
              onClick={handleContactUsOnClick}
            />
          </ContactUsButtonContainer>
        )}
      </ProviderContainer>
      <ServicesContainer>
        {isViewScheduleEnabledLD && (
          <ServiceAvailabilityButtonWrapper>
            <StyledLink to={`${url}/${serviceAvailabilityButtonUrl}`} onClick={trackViewScheduleClick}>
              <ServiceAvailabilityButton
                data-testid="view-schedule"
                text={intl.formatMessage({ id: 'view_schedule' })}
                variant="primary"
              />
            </StyledLink>
          </ServiceAvailabilityButtonWrapper>
        )}
        <UpcomingBookings />
      </ServicesContainer>
      {enableServiceBookingPaymentsMigration && <PaymentRouter />}
      <Switch>
        <Route path={`${path}/swipe-payment/:cartId`}>
          <SwipePaymentIFrame reverseAnimation={reverseAnimation} currentOrderType={currentOrderType} />
        </Route>
        <Route path={`${path}/payment/:cartId`} exact>
          <PaymentIFrame
            currentOrderType={currentOrderType}
            reverseAnimation={reverseAnimation}
            toggleReverseAnimation={toggleReverseAnimation}
          />
        </Route>
        <Route path={`${path}/register-contract`} exact>
          <RegisterContractAgreementModal
            showMembershipsModal={showMembershipsModal}
            setCurrentOrderType={setCurrentOrderType}
          />
        </Route>
      </Switch>
    </MainContainer>
  ) : (
    <LoadingSkeleton />
  );
};
