import styled from 'styled-components';

export interface LoadingContainerProps {
  isLoading?: boolean;
  isIframeLoading?: boolean;
  isModal?: boolean;
}

export const Container = styled.div<LoadingContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isLoading }) => isLoading && 'center'};
  height: ${({ isIframeLoading, isModal }) => (isIframeLoading || isModal ? '100%' : '100vh')};
  align-items: center;
  gap: 80px;
`;
