import React from 'react';

import { useServiceTranslations } from 'pages/event-details/hooks/use-service-translations.hook';
import { Service } from 'store/availableServices/types';
import {
  Container,
  Content,
  EventInfoContainer,
  HostContainer,
  Section,
  Subtitle,
  Title,
  TitleContainer,
} from 'pages/event-details/components/event-details-content/styles';
import { useLocale } from 'hooks/use-locale.hook';
import { ServiceCalendar, ServiceMap } from 'pages/class-details/components';
import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';
import { useIntl } from 'react-intl';
import { LIMITED_SPOTS_TEXT_THRESHOLD } from 'pages/event-details/constants';

interface EventDetailsContentProps {
  service: Service;
}

export const EventDetailsContent = ({ service }: EventDetailsContentProps): JSX.Element => {
  const { title, subtitle, description } = useServiceTranslations({ service });
  const locale = useLocale();
  const intl = useIntl();
  const spotsAvailable =
    service.capacity && service.capacity - service.number_of_bookings + service.number_of_cancellations;
  const limitedCapacitySubtitle =
    spotsAvailable > LIMITED_SPOTS_TEXT_THRESHOLD
      ? intl.formatMessage({ id: 'limited_available_spots' })
      : intl.formatMessage({ id: 'spots_left' }, { spotsAvailable });

  return (
    <Container>
      <TitleContainer>
        <Title data-testid="event-title">{title}</Title>
        <Subtitle data-testid="event-subtitle">{subtitle}</Subtitle>
      </TitleContainer>
      <EventInfoContainer>
        <ServiceCalendar selectedService={service} locale={locale} showDuration={false} data-testid="event-calendar" />
        {service.capacity && (
          <IconBlock
            icon="calendar-circle-user"
            iconType="fal"
            title={intl.formatMessage({ id: 'limited_capacity' })}
            subtitle={<i>{limitedCapacitySubtitle}</i>}
            data-testid="event-capacity"
          />
        )}
        {service.address && <ServiceMap selectedService={service} intl={intl} data-testid="event-location" />}
      </EventInfoContainer>
      {service.host_custom_text && (
        <HostContainer data-testid="event-host">
          {intl.formatMessage({ id: 'hosted_by' })} <b>{service.host_custom_text}</b>
        </HostContainer>
      )}
      <Section data-testid="event-description">
        <Content element="p">{description}</Content>
      </Section>
    </Container>
  );
};
