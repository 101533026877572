import { ReceiptContainer, DoublePane, LeftPane, RightPane } from '../styles';
import React from 'react';
import { TransactionDetails } from 'store/transactions/types';
import { ReceiptOverview } from './receipt-overview';
import { ReceiptSummary } from './receipt-summary';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { CancelBookingContent } from './cancel-booking-content';
import { TransactionDetailsServiceTypes } from 'shared/consts';

interface SwipeReceiptContentProps {
  transaction: TransactionDetails;
  isSwipeModalContent: boolean;
  onCloseClick?: VoidFunction;
}

export const SwipeReceiptContent = ({ isSwipeModalContent, transaction, onCloseClick }: SwipeReceiptContentProps) => {
  const { confirmCancel } = useSearchParams();

  return confirmCancel ? (
    <CancelBookingContent />
  ) : (
    <ReceiptContainer data-testid="receipt-swipe-modal">
      {transaction && (
        <>
          <DoublePane>
            <LeftPane className="left-pane">
              <ReceiptOverview isSwipeModalContent={isSwipeModalContent} />
            </LeftPane>
            <RightPane
              className="right-pane"
              hasFooter={transaction?.details?.service?.type === TransactionDetailsServiceTypes.EVENT}
            >
              <ReceiptSummary isSwipeModalContent={isSwipeModalContent} onCloseClick={onCloseClick} />
            </RightPane>
          </DoublePane>
        </>
      )}
    </ReceiptContainer>
  );
};
