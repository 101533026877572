import React from 'react';
import { Container, Title, Description, BoldText, Content } from './styles';
import { useIntl } from 'react-intl';
import { CancelButtonBlock } from './components/cancel-button-block';
import { useCancelBooking } from './hooks/use-cancel-booking.hook';

export const CancelBookingContent = () => {
  const intl = useIntl();
  const { eventWeekDay, eventDateMonthLong, eventDateDay, formatEventStartTime, vendorName, onCancelButtonClick } =
    useCancelBooking();

  return (
    <Container data-testid="cancel-booking-content">
      <Content>
        <Title>{intl.formatMessage({ id: 'cancel_booking?' })}</Title>
        <Description>
          {intl.formatMessage(
            { id: 'you_are_about_cancel_booking' },
            {
              vendorName: <BoldText>{vendorName}</BoldText>,
              bookingTime: (
                <BoldText>
                  {`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}, ${intl.formatMessage({
                    id: 'at',
                  })} ${formatEventStartTime}`}
                </BoldText>
              ),
            },
          )}
        </Description>
      </Content>
      <CancelButtonBlock onCancel={onCancelButtonClick} />
    </Container>
  );
};
