import styled from 'styled-components';

export const DIMENSIONS = {
  SMALL: '480px',
  MEDIUM: '769px',
  LARGE: '1025px',
};

export interface CustomSizes {
  width?: string;
  height?: string;
  paddings?: Array<string>;
}

export const Wrapper = styled.div<CustomSizes>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${({ paddings }) => `
    padding: ${paddings?.join(' ')};
  `};
  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    transform: translate(0, 45%);
  } ;
`;
