import React, { useRef, useState } from 'react';

import { Footer } from 'components/footer';
import { ImageSkeleton } from 'components/image/styles';
import { PaymentRouter } from 'components/payment-router';
import { ServiceSkeleton } from 'components/service-skeleton/service-skeleton';
import { EventDetailsContent } from 'pages/event-details/components/event-details-content/event-details-content';
import { useServiceTranslations } from 'pages/event-details/hooks/use-service-translations.hook';
import { useSelector } from 'react-redux';
import { IMG_URL } from 'shared/consts';
import { imageIsLoadedSelector } from 'store/availableServices/selectors';

import {
  BackArrowMobileHeader,
  ScrollableTrayContainer,
  useBackArrowMobileHeader,
} from '@hqo/react-components-library';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';

import { IMAGE_PLACEHOLDER_HEIGHT } from './constants';
import { useEventDetailsNavigation } from './hooks/use-event-details-navigation.hook';
import { useEventDetails } from './hooks/use-event-details.hook';
import { Container, EventDetailsContainer, PageContainer, StyledImage, StyledMobileHeaderWrapper } from './styles';

export const EventDetails = (): JSX.Element => {
  const backArrowMobileHeaderRef = useRef(null);
  const eventDetailsContainerRef = useRef(null);
  const scrollableContainerRef = useRef(null);
  const isMobileDevice = useIsSmallViewportWidth();
  const { service, isLoading } = useEventDetails();
  const [imageHeight, setImageHeight] = useState<number>(IMAGE_PLACEHOLDER_HEIGHT);
  const imageExist = useSelector(imageIsLoadedSelector);
  const { image_url, cta_label } = useServiceTranslations({ service });
  const { handleBackButtonClick } = useEventDetailsNavigation();

  useBackArrowMobileHeader({
    backArrowMobileHeaderRef,
    containerRef: eventDetailsContainerRef,
    scrollableContainerRef,
  });

  if (isLoading) {
    return <ServiceSkeleton />;
  }

  return (
    <PageContainer data-testid="event-details-page-container">
      {isMobileDevice && (
        <StyledMobileHeaderWrapper>
          <BackArrowMobileHeader
            backArrowMobileHeaderRef={backArrowMobileHeaderRef}
            handleOnClick={handleBackButtonClick}
          />
        </StyledMobileHeaderWrapper>
      )}
      <Container>
        <EventDetailsContainer ref={eventDetailsContainerRef}>
          <StyledImage
            skeleton={imageExist ? null : <ImageSkeleton />}
            src={IMG_URL + image_url}
            alt="event image"
            setImageHeight={setImageHeight}
          />
          <ScrollableTrayContainer
            isMobileDevice={isMobileDevice}
            scrollableContainerRef={scrollableContainerRef}
            marginTop={imageHeight}
          >
            <EventDetailsContent service={service} />
          </ScrollableTrayContainer>
        </EventDetailsContainer>
        <PaymentRouter />
        <Footer service={service} ctaLabel={cta_label} />
      </Container>
    </PageContainer>
  );
};
