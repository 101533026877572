import { useSelector } from 'react-redux';
import { selectCart } from 'store/cart/selectors';
import { formatSavedPaymentMethodLabel } from 'utils/formatSavedPaymentMethodLabel';
import { formatDate, getFormattedEventTime } from 'utils';
import { PaymentMethodTypes, SavedPaymentMethod } from 'store/payment/types';
import { ORDER_TYPES } from 'shared/consts';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { useLocale } from 'hooks/use-locale.hook';
import { Item } from 'store/cart/types';
import { useIntl } from 'react-intl';
import { PaymentMethodLabels, UsePaymentLabelsReturnValues } from '../types';

// eslint-disable-next-line max-lines-per-function
export const usePaymentLabels = (
  isPaymentMethodsAvailable: boolean,
  isFreeCart: boolean,
  paymentMethod: SavedPaymentMethod,
): UsePaymentLabelsReturnValues => {
  const cart = useSelector(selectCart);
  const intl = useIntl();
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const locale = useLocale();
  const { capitalizedBrand, formattedExpMonth, formattedExpYear } = formatSavedPaymentMethodLabel(paymentMethod) || {};
  const { eventWeekDayLong, eventDateMonthLong, eventDateDay } = formatDate(
    cart?.items[0]?.service_booking?.start_at,
    locale,
    buildingTimeZone,
  );

  const formatEventStartTime = getFormattedEventTime(
    cart?.items[0]?.service_booking?.start_at,
    locale,
    buildingTimeZone,
  );

  const paymentMethodLabels: PaymentMethodLabels = {
    eventWeekDay: eventWeekDayLong,
    eventDateMonthLong,
    formatEventStartTime,
    eventDateDay,
  };

  const resolveSubtitleId = (): string => {
    if (cart.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP) {
      return 'you_are_about_book_membership';
    }

    return 'you_are_about_book';
  };

  const resolveCheckoutButtonId = (): string => {
    if (!isPaymentMethodsAvailable && (!isFreeCart || cart.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP)) {
      return 'add_payment_method';
    }
    if (cart.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP) {
      return isFreeCart ? 'confirm_and_checkout' : 'checkout_and_pay';
    }
    return 'book_service';
  };

  const resolveCheckoutItemSubtitleText = (item: Item): string => {
    if (cart.type !== ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP) {
      return intl.formatMessage({ id: 'with_instructor' }, { instructorName: item.display_info.description1 });
    }
    return '';
  };

  const resolvePaymentMethodText = (paymentMethodType: PaymentMethodTypes) => {
    switch (paymentMethodType) {
      case PaymentMethodTypes.CREDIT_CARD:
        return intl.formatMessage(
          {
            id: 'saved_payment_method_option',
          },
          {
            cardBrand: capitalizedBrand,
            cardLastFour: paymentMethod?.card_last_four,
            cardExpMonth: formattedExpMonth,
            cardExpYear: formattedExpYear,
          },
        );
      case PaymentMethodTypes.INVOICE:
        return intl.formatMessage({ id: 'invoice_text' });

      default:
        return null;
    }
  };

  return {
    paymentMethodLabels,
    resolveSubtitleId,
    resolveCheckoutButtonId,
    resolveCheckoutItemSubtitleText,
    resolvePaymentMethodText,
  };
};
