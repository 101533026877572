export interface AvailableServicesState {
  availableServices: AvailableServices;
  getAvailableServices: AsyncActionTypes;
  currentAvailableService: Service;
  selectedAvailableService: Partial<Service>;
  getCurrentAvailableService: AsyncActionTypes;
  serviceTypes: AvailableServiceTypes;
  getServiceTypes: AsyncActionTypes;
  selectedDate: Date;
  isBookingFromClassPage: boolean;
  imageIsLoaded: boolean;
}

export interface AvailableServicesResponse {
  data: AvailableServices;
}

export interface AvailableServiceResponse {
  data: {
    service: Service;
  };
}

export interface AvailableServicesRequest {
  ownerType: string;
  ownerUuid: string;
  companyUuid: string;
  availabilityStart?: string;
  availabilityEnd?: string;
  serviceTypeId?: string;
}

export interface CurrentAvailableServiceRequest {
  ownerType: string;
  ownerUuid: string;
  companyUuid: string;
  serviceUuid: string;
  availabilityStart?: string;
  availabilityEnd?: string;
  serviceTypeId?: string;
}

export interface AvailableServices {
  first_availability: string;
  services: Service[];
}

export interface Service {
  id?: string | number;
  uuid?: string;
  building_id?: number;
  type?: string;
  name?: string;
  admin_name?: string;
  price?: number;
  currency?: CurrencyTypeEnum;
  can_access?: boolean;
  future_booking_limit?: string;
  description?: string;
  description_uuid?: string;
  requires_membership?: boolean;
  business_hours?: boolean[];
  hero_image?: string;
  location?: Location;
  start_at?: string;
  end_at?: string;
  bookable_start?: string;
  bookable_end?: string;
  duration?: number;
  availabilities?: Availability[];
  service_block_periods?: ServiceBlockPeriod[];
  service_provider_id?: number;
  user_rating?: number;
  waitlist?: WaitList;
  capacity?: number;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  service_type_id?: number;
  owner_type?: TraitOwnerTypes;
  address?: string;
  image_url?: string;
  name_uuid?: string;
  title?: string;
  subtitle?: string;
  confirmation_email?: string;
  host_custom_text?: string;
  host_custom_text_uuid?: string;
  host_company_uuid?: string;
  directions?: string;
  virtual_url?: string;
  virtual_directions?: string;
  virtual_text?: string;
  duration_minutes?: number;
  display_start_at?: string;
  display_end_at?: string;
  display_calendar_icon?: boolean;
  content_name?: string;
  content_uuid?: string;
  number_of_bookings?: number;
  number_of_cancellations?: number;
  earnings?: number;
  content_status?: ServiceContentStatuses;
  cta_label?: string;
  cta_label_key?: string;
  is_timekit?: boolean;
  fully_booked?: boolean;
  languages?: string[];
  translations?: Record<string, ServiceTranslations>;
  subtitle_uuid?: string;
  title_uuid?: string;
  image_url_uuid?: string;
  class_id?: string;
}

interface ServiceTranslations {
  title: string;
  subtitle: string;
  image_url: string;
  description: string;
  cta_label: string;
}

export enum ServiceContentStatuses {
  NONE = 'NONE',
  SCHEDULED = 'SCHEDULED',
  LIVE = 'LIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum TraitOwnerTypes {
  Building = 'Building',
  BuildingCompany = 'BuildingCompany',
  Company = 'Company',
  User = 'User',
}

interface Location {
  name: string;
  address: string;
}

export enum CurrencyTypeEnum {
  /** Canadian Dollar */
  CAD = 'CAD',
  /** Pound Sterling */
  GBP = 'GBP',
  /** U.S. Dollar */
  USD = 'USD',
  /** Euro */
  EUR = 'EUR',
}

export interface ServiceBlockPeriod {
  start: string;
  end: string;
}

export interface Availability {
  start_at: string;
  end_at: string;
  seats_available?: number;
  seats_booked?: number;
  user_booked?: boolean;
  user_booking_uuid?: string;
  resource_uuid: string;
  resource?: ServiceResource;
  booking_window?: ServiceBookingWindow;
  waitlist?: WaitList;
  duration?: number;
}

interface ServiceResource {
  id: string;
  name: string;
}

export interface ServiceBookingWindow {
  bookable_start?: string;
  bookable_end?: string;
}

export interface WaitList {
  count: number;
  id?: string | number;
  index?: number;
  is_available: boolean;
  is_user_waitlisted: boolean;
  standard_service_booking_id?: string;
}

export interface AvailableServiceView {
  id: number;
  uuid: string;
  adminName?: string;
  bookingWindow?: ServiceBookingWindow;
  currency: CurrencyTypeEnum;
  duration: number;
  price: number;
  requiresMembership: boolean;
  resourceName?: string;
  resourceUuid?: string;
  seatAvailable?: number;
  startTime?: string;
  endTime?: string;
  title: string;
  userBooked?: boolean;
  userWaitlisted: boolean;
  waitlistCount: number;
  waitlistAvailable: boolean;
  canAccess: boolean;
  class_id?: string;
}

export interface ServiceTypesRequest {
  ownerType: string;
  ownerUuid: string;
  companyUuid: string;
  serviceType?: SERVICE_TYPE;
}

export interface ServiceTypesResponse {
  data: AvailableServiceTypes;
}

export interface AvailableServiceTypes {
  serviceTypes: ServiceType[];
}

export interface ServiceType {
  type: SERVICE_TYPE;
  id: string;
  name: string;
  defaultTimeLengthMinutes: number;
}

export enum SERVICE_TYPE {
  GROUP = 'GROUP',
  APPOINTMENT = 'APPOINTMENT',
}
