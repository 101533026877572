import React from 'react';
import { PaymentMethodContainer, Method, Icon, Container, Title, Subtitle, GroupedPaymentMethods } from './styles';
import { useIntl } from 'react-intl';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import { SupportedPaymentMethods } from 'shared/consts';

interface ReceiptPaymentMethodProps {
  title?: string;
  className?: string;
  paymentMethod: string;
  creditsAmount?: string;
  total?: number;
  amounts?: Amounts;
}

interface Amounts {
  totalCharge: number;
  totalDiscount: number;
}

export const ReceiptPaymentMethod = ({
  title = '',
  className,
  paymentMethod,
  creditsAmount = '',
  total,
  amounts,
}: ReceiptPaymentMethodProps): JSX.Element => {
  const intl = useIntl();

  switch (paymentMethod) {
    case SupportedPaymentMethods.INVOICE:
      return (
        <Container className={className}>
          <Title>{intl.formatMessage({ id: 'invoice_text_receipt' })}</Title>
        </Container>
      );
    case SupportedPaymentMethods.CREDIT_CARD:
      if (amounts?.totalDiscount === total) {
        return (
          <Container className={className}>
            <Title>{intl.formatMessage({ id: 'paid_with_credits' })}</Title>
            <Subtitle>{intl.formatMessage({ id: 'credits_remaining' }, { remainingCredits: creditsAmount })}</Subtitle>
          </Container>
        );
      }

      if (amounts?.totalCharge === total) {
        return (
          <PaymentMethodContainer className={className}>
            {total === 0 ? (
              <Title>{intl.formatMessage({ id: 'card_not_charged' })}</Title>
            ) : (
              <>
                <Icon icon={faCreditCard} size="1x" />
                <Method>{title}</Method>
              </>
            )}
          </PaymentMethodContainer>
        );
      }

      if (amounts?.totalCharge + amounts?.totalDiscount === total) {
        return (
          <GroupedPaymentMethods>
            <Container className={className}>
              <Title>{intl.formatMessage({ id: 'paid_with_credits' })}</Title>
              <Subtitle>
                {intl.formatMessage({ id: 'credits_remaining' }, { remainingCredits: creditsAmount })}
              </Subtitle>
            </Container>
            <PaymentMethodContainer className={className}>
              <Icon icon={faCreditCard} size="1x" />
              <Method>{title}</Method>
            </PaymentMethodContainer>
          </GroupedPaymentMethods>
        );
      }

      return (
        <PaymentMethodContainer className={className}>
          {total === 0 ? (
            <Title>{intl.formatMessage({ id: 'card_not_charged' })}</Title>
          ) : (
            <>
              <Icon icon={faCreditCard} size="1x" />
              <Method>{title}</Method>
            </>
          )}
        </PaymentMethodContainer>
      );
    default:
      return null;
  }
};
