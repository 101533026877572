import { Button, DIMENSIONS, ExclamationMark, PaymentMethod } from '@hqo/react-components-library';
import { QUICK_CHECKOUT_BREAK_POINT } from 'shared/consts';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';
import styled from 'styled-components';
import { QuickCheckoutButtonProps } from './types';

export const ContentContainer = styled.div`
  margin: 52px 32px 20px 32px;
  display: grid;
  @media only screen and (max-width: ${QUICK_CHECKOUT_BREAK_POINT}px) {
    margin: 39px 16px 20px 16px;
  }
`;

export const QuickCheckoutContent = styled.div`
  @media only screen and (max-width: ${DIMENSIONS.SMALL}) {
    .text-container {
      flex-basis: 75%;
      flex-grow: 0;
    }

    .subtitle-text {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-inline-box;
    }
  }
`;

export const ButtonContainer = styled.div<QuickCheckoutButtonProps>`
  margin-top: ${({ isNext, isFreeCart }) => (isNext || isFreeCart ? '40px' : '0px')};

  @media only screen and (min-width: ${DIMENSIONS.MEDIUM}) {
    margin-top: 0;
  }

  @media only screen and (max-width: ${QUICK_CHECKOUT_BREAK_POINT}px) {
    background: ${({ theme }) => theme.colors.$white};
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 30px);
    height: 48px;
    padding: 10px 15px;
  }
`;

export const StyledButton = styled(Button)`
  width: 215px;
  height: 36px;
  border-color: ${({ theme }) => theme.colors.greys.darkGrey6};
  align-self: flex-end;
  -webkit-tap-highlight-color: transparent;

  @media only screen and (max-width: ${QUICK_CHECKOUT_BREAK_POINT}px) {
    width: 100%;
    height: 100%;
    margin-top: 0;
  }
`;

export const StyledTitleBlock = styled(TitleBlock)``;

export const StyledPriceBlock = styled.div`
  margin-top: 15px;
`;

export const StyledPaymentBlock = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
`;

export const StyledPaymentMethod = styled(PaymentMethod)`
  margin-bottom: 5px;
  width: 100%;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ErrorLabel = styled.div`
  font: italic 12px/17px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$adminPink};
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

export const StyledErrorIcon = styled(ExclamationMark)`
  margin: 0 5px;
  flex-shrink: 0;
`;
