import { DIMENSIONS } from '@hqo/react-components-library';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BackLinkWrapper = styled.div`
  padding-left: 20px;
  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding-left: 0;
  }
`;

export const ServiceTypeWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  padding: 16px 32px;
  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding-left: 16px;
  }
`;

export const Title = styled.div`
  font: 700 28px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    line-height: 33px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
`;

export const AppointmentsContentWrapper = styled.div<{ isBackButtonVisible: boolean }>`
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  margin-top: 100px;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-top: 0;
    height: ${({ isBackButtonVisible }) => (isBackButtonVisible ? 'calc(100vh - 110px)' : 'calc(100vh - 78px)')};
  } ;
`;
