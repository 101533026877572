import { DIMENSIONS } from '@hqo/react-components-library';
import styled from 'styled-components';

export const Title = styled.div`
  font: 700 24px/24px ${({ theme }) => theme.fonts.join()};

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-top: 25px;
  }
`;

export const Description = styled.div`
  font: 400 16px/22px ${({ theme }) => theme.fonts.join()};
`;

export const BoldText = styled.span`
  font-weight: 500;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
