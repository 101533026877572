import React from 'react';

import { Cart } from 'components/cart';
import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  BookingStatus,
  ORDER_TYPES,
  SupportedPaymentMethods,
  TRANSACTION_TYPES,
  TransactionDetailsServiceTypes,
} from 'shared/consts';
import { selectBuildingLocale, selectBuildingTimeZone } from 'store/building/selectors';
import { selectCart } from 'store/cart/selectors';
import { selectPaymentMethods } from 'store/payment/selectors';
import { selectTransaction, selectTransactionDisplay, selectTransactionStatus } from 'store/transactions/selectors';
import { formatCurrency } from 'utils/formatCurrency';
import { formatDate } from 'utils/formatDate';
import { formatSavedPaymentMethodLabel } from 'utils/formatSavedPaymentMethodLabel';
import { formatSavedPaymentMethodLabelFromTransaction } from 'utils/formatSavedPaymentMethodLabelFromTransaction';
import { getReceiptInfo } from 'utils/getReceiptInfo';
import { getReceiptInfoFromTransaction } from 'utils/getReceiptInfoFromTransaction';

import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';

import { ReceiptPaymentMethod } from './receipt-payment-method';
import {
  ButtonContainer,
  CtaButton,
  IconBlockContainer,
  PaymentMethodContainer,
  PaymentMethodTitle,
  ServiceDetails,
  StyledSummarySubtitle,
  StyledTitleBlock,
  SummaryContainer,
} from './styles';
import { useReceipt } from 'components/payment/receipt/use-receipt.hook';
import { selectCurrentAvailableService } from 'store/availableServices/selectors';

interface SummaryProps {
  isSwipeModalContent?: boolean;
  onCloseClick?: VoidFunction;
}

export const ReceiptSummary = ({ isSwipeModalContent = false, onCloseClick }: SummaryProps) => {
  const intl = useIntl();
  const { handleCancelClick } = useReceipt();
  const isMobileDevice = useIsSmallViewportWidth();
  const cart = useSelector(selectCart);
  const paymentMethods = useSelector(selectPaymentMethods);
  const paymentMethod = paymentMethods?.find(method => method.default);
  const locale = useLocale();
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const transaction = useSelector(selectTransaction);
  const { transactionUuid } = useSearchParams();
  const transactionDisplay = useSelector(selectTransactionDisplay);
  const transactionStatus = useSelector(selectTransactionStatus);
  const service = useSelector(selectCurrentAvailableService);
  const { enableServiceBookingCancellation } = useFlags();

  const isCancelBookingLinkVisible = enableServiceBookingCancellation && transactionStatus !== BookingStatus.CANCELLED;

  const { capitalizedBrand, formattedExpMonth, formattedExpYear } =
    transaction?.payment_method?.type?.toUpperCase() === SupportedPaymentMethods.CREDIT_CARD &&
    (transactionUuid
      ? formatSavedPaymentMethodLabelFromTransaction(transaction?.payment_method)
      : formatSavedPaymentMethodLabel(paymentMethod));

  const bookingStart = transactionUuid ? transaction?.details?.start_at : cart?.items[0]?.service_booking?.start_at;
  const { eventWeekDayLong, eventDateMonthLong, eventDateDay, eventDateYear } = formatDate(
    bookingStart,
    locale as string,
    buildingTimeZone,
  );

  const isServiceBookingMemberType = transactionUuid
    ? transaction?.type === TRANSACTION_TYPES.MEMBERSHIP
    : cart?.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP;

  const isServiceBookingEvent = cart
    ? cart.type === ORDER_TYPES.SERVICE_BOOKING_EVENT
    : transaction?.details?.service?.type === TransactionDetailsServiceTypes.EVENT;

  const vendorFormattedAddress = transactionUuid
    ? transactionDisplay?.vendor?.formatted_address
    : cart?.vendor?.formatted_address;

  const vendorName = transactionUuid ? transactionDisplay?.vendor?.name : cart?.vendor?.name;

  const mapProps = isServiceBookingEvent
    ? {
        title: vendorFormattedAddress,
        subtitle: service ? service.directions : '',
      }
    : {
        title: vendorName ?? 'default_building_name',
        subtitle: vendorFormattedAddress ?? '',
      };

  return (
    <>
      <SummaryContainer data-testid="receipt-summary">
        {!isServiceBookingMemberType && (
          <ServiceDetails isSwipeModalContent={isSwipeModalContent}>
            <StyledSummarySubtitle>
              {intl.formatMessage({
                id: transactionUuid
                  ? getReceiptInfoFromTransaction(transaction, locale as string, buildingTimeZone).title
                  : getReceiptInfo(cart, locale as string, buildingTimeZone).title,
              })}
            </StyledSummarySubtitle>
            {!isServiceBookingEvent && (
              <StyledTitleBlock
                spacing={12}
                title={
                  transactionUuid
                    ? transactionDisplay?.items[0]?.display_info?.title
                    : cart?.items[0].display_info.title
                }
                subtitle={intl.formatMessage(
                  { id: 'with_instructor' },
                  {
                    instructorName: transactionUuid
                      ? transactionDisplay?.items[0]?.display_info?.description1
                      : cart?.items[0].display_info.description1,
                  },
                )}
              />
            )}
            <IconBlockContainer>
              <IconBlock
                icon="calendar"
                iconType="fal"
                title={`${eventWeekDayLong}, ${eventDateMonthLong} ${eventDateDay}, ${eventDateYear}`}
                subtitle={
                  transactionUuid
                    ? getReceiptInfoFromTransaction(transaction, locale as string, buildingTimeZone).eventBlockSubtitle
                    : getReceiptInfo(cart, locale as string, buildingTimeZone).eventBlockSubtitle
                }
                action={!isServiceBookingEvent && isCancelBookingLinkVisible}
                actionText={intl.formatMessage({ id: 'cancel_book' })}
                onActionClick={handleCancelClick}
              />
              <IconBlock
                icon="map-marker-alt"
                linkText={vendorFormattedAddress ? intl.formatMessage({ id: 'open_in_maps' }) : ''}
                linkRef={`https://www.google.com/maps?q=${vendorFormattedAddress}`}
                iconType="fal"
                title={mapProps.title}
                subtitle={mapProps.subtitle}
              />
            </IconBlockContainer>
          </ServiceDetails>
        )}
        {!!transaction?.payment_method?.type && (
          <>
            {isServiceBookingMemberType && !isMobileDevice && (
              <StyledSummarySubtitle>
                {intl.formatMessage({
                  id: transactionUuid
                    ? getReceiptInfoFromTransaction(transaction, locale as string, buildingTimeZone).title
                    : getReceiptInfo(cart, locale as string, buildingTimeZone).title,
                })}
              </StyledSummarySubtitle>
            )}
            <Cart cart={cart} isCheckoutView={false} />
            <PaymentMethodContainer>
              <PaymentMethodTitle>{intl.formatMessage({ id: 'payment' })}</PaymentMethodTitle>
              <ReceiptPaymentMethod
                title={intl.formatMessage(
                  {
                    id: 'saved_payment_method_option',
                  },
                  {
                    cardBrand: capitalizedBrand,
                    cardLastFour: transaction?.payment_method?.last_four,
                    cardExpMonth: formattedExpMonth,
                    cardExpYear: formattedExpYear,
                  },
                )}
                paymentMethod={transaction?.payment_method.type?.toUpperCase()}
                creditsAmount={formatCurrency(
                  (transaction?.payment_method?.remaining_points || 0) / 100,
                  transaction?.currency_type,
                  buildingLocale,
                )}
                total={transactionUuid ? transactionDisplay?.total_summary?.total : cart?.total_summary?.total}
              />
            </PaymentMethodContainer>
          </>
        )}
        {isServiceBookingEvent && isSwipeModalContent && (
          <ButtonContainer>
            <CtaButton
              data-testid="footer-cta-button"
              text={intl.formatMessage({ id: 'done' })}
              onClick={onCloseClick}
            />
          </ButtonContainer>
        )}
      </SummaryContainer>
    </>
  );
};
