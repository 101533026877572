import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { goBack, replace, push } from 'connected-react-router';
import { IntlShape, useIntl } from 'react-intl';
import moment from 'moment-timezone';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { track } from '@hqo/web-tracking';
import { NavBackLink } from '@hqo/react-components-library/dist/molecules/navBackLink';
import {
  BackLinkWrapper,
  IconsContainer,
  TitleContainer,
  Page,
  Container,
  StyledImage,
  Title,
  Subtitle,
  FooterWrapper,
  StyledBookButton,
  DescriptionWrapper,
  DescriptionHeader,
  DescriptionContent,
  ButtonWrapper,
  ClassDetailsContainer,
  ButtonContainer,
  MembershipRequiredTitle,
  StyledPriceBlock,
  StyledPrice,
} from 'pages/class-details/styles';
import { ACTION_STATUSES, BookingStatus, CATEGORIES, TRACK_EVENTS, TRACK_EVENT_TYPES } from 'shared/consts';
import { ItemType, OrderType } from 'store/cart/types';
import { ProviderParams } from 'shared/types';
import { Service, SERVICE_TYPE } from 'store/availableServices/types';
import {
  selectCurrentServiceProvider,
  selectFormattedUserContracts,
  selectIsUserRegistered,
  selectServiceProviderAdapter,
} from 'store/serviceProviders/selectors';
import { selectAvailableServices, selectIsBookingFromClassPage } from 'store/availableServices/selectors';
import { setBookingFromClassPage, setCurrentAvailableService } from 'store/availableServices/actions';
import { createCart, resetCreateCartStatus } from 'store/cart/actions';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { useBackButtonVisibility } from 'hooks/use-back-button-visibility.hook';
import { ServiceCalendar, ServiceMap, SpotsCounterBlock } from 'pages/class-details/components';
import { useLocale } from 'hooks/use-locale.hook';
import { formatCurrency, isBetweenDatesTime, resolveMembershipPermission } from 'utils';
import { selectCartId, selectCreateCartStatus } from 'store/cart/selectors';
import { PaymentIFrame } from 'components/paymentIFrame';
import { useBooleanState } from '@hqo/react-components-library';
import { configSelector } from 'store/config/selectors';
import { SwipePaymentIFrame } from 'components/swipePaymentIFrame';
import { PaymentRouter } from 'components/payment-router';
import { PaymentModalHandler } from 'components/payment-modal-handler';
import { Receipt } from 'components/payment/receipt';
import { selectTransaction, selectTransactions } from 'store/transactions/selectors';
import { getTransaction } from 'store/transactions/actions';
import { useReceiptScreen } from 'hooks/payment-content/use-receipt-screen.hook';

const renderButtonText = (
  isMembershipRequired: boolean,
  intl: IntlShape,
  selectedService: Service,
  isBooked: boolean,
  isWaitlist: boolean,
  hasInvalidMemberships: boolean,
) => {
  let buttonText = intl.formatMessage({ id: 'book_free_class' });
  if (isWaitlist) {
    buttonText = intl.formatMessage({ id: 'added_waitlist' });
  } else if (isBooked) {
    buttonText = intl.formatMessage({ id: 'view_booking' });
  } else if (hasInvalidMemberships) {
    buttonText = intl.formatMessage({ id: 'book' });
  } else if (isMembershipRequired) {
    buttonText = intl.formatMessage({ id: 'become_member' });
  } else if (
    selectedService.availabilities[0]?.seats_available <= 0 &&
    selectedService.availabilities[0]?.waitlist?.is_available
  ) {
    buttonText = intl.formatMessage({ id: 'join_waitlist' });
  }
  return buttonText;
};

export const ClassDetails = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const timeZone = useTimeZone();
  const locale = useLocale();
  const isBackButtonVisible = useBackButtonVisibility();
  const { serviceBookingLocalTimeUpdate: inLocalTimeLD, enableServiceBookingPaymentsMigration } = useFlags();
  const { classId } = useParams<ProviderParams>();
  const { buildingUuid } = useSelector(configSelector);
  const { path } = useRouteMatch();
  const providerDetails = useSelector(selectCurrentServiceProvider);
  const isBookingFromClassPage = useSelector(selectIsBookingFromClassPage);
  const isUserRegistered = useSelector(selectIsUserRegistered);
  const formattedUserMemberships = useSelector(selectFormattedUserContracts);
  const currentService = useSelector(selectCurrentServiceProvider);
  const createCartStatus = useSelector(selectCreateCartStatus);
  const cartId = useSelector(selectCartId);
  const { value: reverseAnimation, toggle: toggleReverseAnimation } = useBooleanState(false);
  const serviceProviderAdapter = useSelector(selectServiceProviderAdapter);
  const transactions = useSelector(selectTransactions);
  const transaction = useSelector(selectTransaction);
  const { onCloseReceipt: onCloseReceiptAfterBooking } = useReceiptScreen();

  const selectedService = useSelector(selectAvailableServices)?.services?.find(
    service => service?.id?.toString() === classId,
  );

  const {
    name: serviceName,
    availabilities: [availability],
    hero_image,
    description,
    price,
    currency,
  } = selectedService;

  const formattedPrice = price === 0 ? intl.formatMessage({ id: 'free' }) : formatCurrency(price, currency);

  const hasInvalidMemberships = useMemo(
    () => !selectedService.can_access && currentService.user_memberships?.length > 0,
    [selectedService, currentService],
  );

  const isMembershipRequiredTextVisible = useMemo(
    () => !selectedService.can_access && currentService?.user_memberships?.length === 0,
    [selectedService, currentService],
  );

  const isMembershipRequired = resolveMembershipPermission(providerDetails, selectedService?.can_access);

  // Remove once Helix removes Z from date-time strings https://ventureapp.atlassian.net/browse/SONIC-1501
  const bookableStart = inLocalTimeLD
    ? availability?.booking_window?.bookable_start.replace('Z', '')
    : availability?.booking_window?.bookable_start;
  const bookableEnd = inLocalTimeLD
    ? availability?.booking_window?.bookable_end.replace('Z', '')
    : availability?.booking_window?.bookable_end;

  const isClassBookable = isBetweenDatesTime(
    new Date(bookableStart),
    new Date(bookableEnd),
    moment.tz(timeZone).toDate(),
    timeZone,
    inLocalTimeLD,
  );

  const isCTAButtonDisabled =
    availability?.waitlist?.is_user_waitlisted ||
    !selectedService.can_access ||
    (!isMembershipRequired && !isClassBookable);

  const getActiveCTAButtonLabel = useCallback(() => {
    if (isCTAButtonDisabled) {
      return '';
    }
    return renderButtonText(
      isMembershipRequired,
      intl,
      selectedService,
      availability?.user_booked,
      availability?.waitlist?.is_user_waitlisted,
      hasInvalidMemberships,
    );
  }, [isCTAButtonDisabled, selectedService, availability, hasInvalidMemberships, intl, isMembershipRequired]);

  useEffect(() => {
    track(
      TRACK_EVENTS.SERVICE_DETAIL_IMPRESSION,
      {
        type: TRACK_EVENT_TYPES.IMPRESSION,
        is_registered: isUserRegistered,
        user_contracts: formattedUserMemberships,
        button_cta: [getActiveCTAButtonLabel()],
        adapter: serviceProviderAdapter,
        price,
        currency_code: currency,
        category: CATEGORIES.MINDBODY,
        service_id: selectedService.id,
      },
      { sendToPendo: true, sendToHqoTracking: true },
    );
  }, [availability, formattedUserMemberships, isUserRegistered, selectedService]);

  useEffect(() => {
    dispatch(setCurrentAvailableService(selectedService));
  }, [dispatch, selectedService]);

  useEffect(() => {
    if (isBookingFromClassPage && !isMembershipRequired) {
      dispatch(setBookingFromClassPage(false));
      dispatch(
        createCart.request({
          type: OrderType.SERVICE_BOOKING_CLASS,
          owner_type: providerDetails?.owner_type,
          owner_id: providerDetails?.owner_uuid,
          building_uuid: buildingUuid,
          app_instance_config_uuid: providerDetails?.app_instance_config_uuid,
          items: [
            {
              id: selectedService?.id.toString(),
              type: ItemType.SERVICE_BOOKING_CLASS,
              quantity: 1,
              price: selectedService?.price,
              class_id: selectedService?.class_id,
              display_info: {
                title: selectedService?.admin_name,
                description1: selectedService?.availabilities[0].resource.name,
                description2: selectedService?.availabilities[0].start_at,
              },
              service_booking: {
                resource_uuid: selectedService?.availabilities[0].resource_uuid,
                start_at: selectedService?.availabilities[0].start_at,
                end_at: selectedService?.availabilities[0].end_at,
                waitlisted: !!(
                  selectedService?.availabilities[0].seats_available <= 0 &&
                  selectedService?.availabilities[0].waitlist.is_available
                ),
              },
            },
          ],
          booking_type: SERVICE_TYPE.GROUP,
        }),
      );
    }
  }, [isBookingFromClassPage, dispatch, buildingUuid, providerDetails, isMembershipRequired, selectedService]);

  useEffect(() => {
    if (createCartStatus === ACTION_STATUSES.REJECTED) {
      dispatch(resetCreateCartStatus());
    }
    if (createCartStatus === ACTION_STATUSES.FULFILLED && !enableServiceBookingPaymentsMigration) {
      dispatch(resetCreateCartStatus());
      dispatch(replace(`${location.pathname}/payment/${cartId}?quickCheckout=true`));
    }
  }, [createCartStatus, cartId, enableServiceBookingPaymentsMigration]);

  const onViewBookingPress = useCallback(() => {
    const transactionUuid =
      transaction?.uuid ?? transactions.find(findTransaction => findTransaction.details.service_id === classId).uuid;

    dispatch(getTransaction.request(transactionUuid));
    dispatch(push(`${location.pathname}/receipt?transactionUuid=${transactionUuid}`));
  }, [dispatch, transactions, classId, transaction]);

  const onCTAClick = useCallback(() => {
    if (availability?.user_booked) {
      onViewBookingPress();

      return;
    }

    track(TRACK_EVENTS.BOOK_CLICK, { type: TRACK_EVENT_TYPES.ACTION }, { sendToPendo: true, sendToHqoTracking: false });
    dispatch(
      createCart.request({
        type: OrderType.SERVICE_BOOKING_CLASS,
        owner_type: providerDetails?.owner_type,
        owner_id: providerDetails?.owner_uuid,
        building_uuid: buildingUuid,
        app_instance_config_uuid: providerDetails?.app_instance_config_uuid,
        items: [
          {
            id: selectedService?.id.toString(),
            type: ItemType.SERVICE_BOOKING_CLASS,
            quantity: 1,
            price: selectedService?.price,
            class_id: selectedService?.class_id,
            display_info: {
              title: selectedService?.admin_name,
              description1: selectedService?.availabilities[0]?.resource.name,
              description2: inLocalTimeLD
                ? moment(selectedService?.availabilities[0]?.start_at).tz(timeZone, true).toISOString()
                : selectedService?.availabilities[0]?.start_at,
            },
            service_booking: {
              resource_uuid: selectedService?.availabilities[0]?.resource_uuid,
              start_at: inLocalTimeLD
                ? moment(selectedService?.availabilities[0]?.start_at).tz(timeZone, true).toISOString()
                : selectedService?.availabilities[0]?.start_at,
              end_at: inLocalTimeLD
                ? moment(selectedService?.availabilities[0]?.end_at).tz(timeZone, true).toISOString()
                : selectedService?.availabilities[0]?.end_at,
              waitlisted: !!(
                selectedService?.availabilities[0]?.seats_available <= 0 &&
                selectedService?.availabilities[0]?.waitlist.is_available
              ),
            },
          },
        ],
        booking_type: SERVICE_TYPE.GROUP,
      }),
    );
  }, [dispatch, providerDetails, buildingUuid, selectedService, availability?.user_booked]);

  const onPressBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  const onCloseReceipt = useCallback(() => {
    if (availability.user_booked && transaction?.details?.status !== BookingStatus.CANCELLED) {
      dispatch(goBack());
    } else {
      onCloseReceiptAfterBooking();
    }
  }, [dispatch, transaction, availability]);

  return (
    <Page data-testid="class-details-page">
      <Container>
        <ClassDetailsContainer>
          {isBackButtonVisible && (
            <BackLinkWrapper>
              <NavBackLink
                dataTestId="class-details-back-link"
                onClick={onPressBack}
                backLinkText={intl.formatMessage({ id: 'back' })}
              />
            </BackLinkWrapper>
          )}
          <StyledImage src={hero_image} alt="service_logo" />
          <TitleContainer>
            <Title>{serviceName}</Title>
          </TitleContainer>
          <Subtitle>{`${intl.formatMessage({ id: 'with' })} ${availability?.resource?.name}`}</Subtitle>
          <IconsContainer>
            <ServiceCalendar selectedService={selectedService} locale={locale} />
            <ServiceMap selectedService={selectedService} intl={intl} />
          </IconsContainer>
          {selectedService.description && (
            <DescriptionWrapper>
              <DescriptionHeader>{intl.formatMessage({ id: 'class_description' })}</DescriptionHeader>
              <DescriptionContent dangerouslySetInnerHTML={{ __html: `${description.replace(/\n/g, '<br/>')}` }} />
            </DescriptionWrapper>
          )}
          <DescriptionWrapper>
            <DescriptionHeader>{intl.formatMessage({ id: 'cancellation_policy' })}</DescriptionHeader>
            <DescriptionContent>{intl.formatMessage({ id: 'cancellation_policy_text' })}</DescriptionContent>
          </DescriptionWrapper>
        </ClassDetailsContainer>
        <FooterWrapper>
          {isMembershipRequiredTextVisible && (
            <MembershipRequiredTitle>{intl.formatMessage({ id: 'membership_required_text' })}</MembershipRequiredTitle>
          )}
          <ButtonContainer>
            <ButtonWrapper>
              <StyledPriceBlock>
                <StyledPrice>{formattedPrice}</StyledPrice>
                {!selectedService.availabilities[0]?.waitlist?.is_available && (
                  <SpotsCounterBlock availability={availability} />
                )}
              </StyledPriceBlock>
              <StyledBookButton
                data-testid="book-button"
                variant="primary"
                disabled={isCTAButtonDisabled}
                text={renderButtonText(
                  isMembershipRequired,
                  intl,
                  selectedService,
                  availability?.user_booked,
                  availability?.waitlist?.is_user_waitlisted,
                  hasInvalidMemberships,
                )}
                onClick={onCTAClick}
              />
            </ButtonWrapper>
          </ButtonContainer>
        </FooterWrapper>
        {enableServiceBookingPaymentsMigration && <PaymentRouter />}
        <Switch>
          <Route path={`${path}/swipe-payment/:cartId`}>
            <SwipePaymentIFrame
              reverseAnimation={reverseAnimation}
              currentOrderType={OrderType.SERVICE_BOOKING_CLASS}
            />
          </Route>
          <Route path={`${path}/payment/:cartId`} exact>
            <PaymentIFrame
              currentOrderType={OrderType.SERVICE_BOOKING_CLASS}
              reverseAnimation={reverseAnimation}
              toggleReverseAnimation={toggleReverseAnimation}
            />
          </Route>
          <Route path={`${path}/receipt`}>
            <PaymentModalHandler
              content={<Receipt onCloseReceipt={onCloseReceipt} />}
              onClose={onCloseReceipt}
              withLeftArrowButton={false}
            />
          </Route>
        </Switch>
      </Container>
    </Page>
  );
};
