import * as userActions from '../user/actions';
import { API_URL_LS_KEY, APP_BRAND_LS_KEY, BUILDING_UUID_LS_KEY, LOCALE_LS_KEY } from 'shared/consts';

import { ConfigState } from './types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { getDefaultApiUrl } from 'store/config/get-default-url.util';
import { saveExternalConfig } from './actions';

export const initialState: ConfigState = {
  apiUrl: localStorage.getItem(API_URL_LS_KEY) ?? getDefaultApiUrl(),
  appBrand: localStorage.getItem(APP_BRAND_LS_KEY) ?? null,
  buildingUuid: localStorage.getItem(BUILDING_UUID_LS_KEY) ?? null,
  locale: localStorage.getItem(LOCALE_LS_KEY) ?? null,
};

const apiUrlReducer = createReducer(initialState.apiUrl)
  .handleAction(userActions.externalLogin.request, (_state, action) => {
    const { apiUrl } = action.payload;

    if (apiUrl) {
      localStorage.setItem(API_URL_LS_KEY, apiUrl);
    } else {
      localStorage.removeItem(API_URL_LS_KEY);
    }

    return apiUrl ?? getDefaultApiUrl();
  })
  .handleAction(userActions.externalLogin.failure, () => {
    localStorage.removeItem(API_URL_LS_KEY);

    return getDefaultApiUrl();
  });

const getExternalConfigSaveReducer = <K extends keyof ConfigState>(key: K, lsKey: string) => {
  return (state: ConfigState[K], action: ReturnType<typeof saveExternalConfig>): ConfigState[K] => {
    let value: string | undefined | null = action.payload[key];

    if (value) {
      if (key === 'locale') {
        value = value.toLowerCase().replace('_', '-');
      }
      localStorage.setItem(lsKey, value);
    }

    return value ?? state;
  };
};

const appBrandReducer = createReducer(initialState.appBrand).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer('appBrand', APP_BRAND_LS_KEY),
);

const buildingUuidReducer = createReducer(initialState.buildingUuid).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer('buildingUuid', BUILDING_UUID_LS_KEY),
);

const localeReducer = createReducer(initialState.locale).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer('locale', LOCALE_LS_KEY),
);

export default combineReducers({
  apiUrl: apiUrlReducer,
  appBrand: appBrandReducer,
  buildingUuid: buildingUuidReducer,
  locale: localeReducer,
});
