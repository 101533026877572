import { FooterContainer, CtaButton, ButtonContainer, ButtonWrapper, DateTimeContainer } from './styles';
import React from 'react';
import { SelectedDateTime } from '../selected-date-time/selected-date-time';
import { Service } from 'store/availableServices/types';
import { useCtaButton } from './hooks/useCtaButton.hook';

interface FooterProps {
  service: Service;
  ctaLabel: string;
}

export const Footer = ({ service, ctaLabel }: FooterProps): JSX.Element => {
  const { handleClick, isButtonLoadingAndDisabled } = useCtaButton(service);

  return (
    <FooterContainer data-testid="event-details-footer">
      <ButtonContainer>
        <ButtonWrapper>
          <DateTimeContainer>
            {!!service.price && <SelectedDateTime />}
            <CtaButton
              disabled={isButtonLoadingAndDisabled}
              loading={isButtonLoadingAndDisabled}
              text={ctaLabel}
              onClick={handleClick}
              data-testid="book-button"
              variant="Primary"
            />
          </DateTimeContainer>
        </ButtonWrapper>
      </ButtonContainer>
    </FooterContainer>
  );
};
