import { TRACK_EVENTS, TRACK_EVENT_TYPES } from 'shared/consts';
import React, { useCallback } from 'react';

import { Availability } from 'store/availableServices/types';
import { ButtonProps } from '@hqo/react-components-library';
import { StyledBookingTile } from './styles';
import { track } from '@hqo/web-tracking';

export interface AvailabilityListProps {
  service: Availability;
  status: string | undefined;
  actionButton: ButtonProps;
  disabled: boolean;
  onActionButtonClick?: (service: Availability) => void;
  onClick?: (service: Availability) => void;
  formattedPrice?: string;
  formattedDate?: string;
  formattedTimeSlot?: string;
  title: string;
  subtitle: string;
  displayConfirmedIcon?: boolean;
}

export const BookingTile = ({
  service,
  status,
  disabled,
  onClick,
  onActionButtonClick,
  actionButton,
  formattedPrice,
  formattedDate,
  formattedTimeSlot,
  title,
  subtitle,
  displayConfirmedIcon,
}: AvailabilityListProps) => {
  const handleOnActionButtonClick = useCallback(() => {
    track(TRACK_EVENTS.BOOK_CLICK, { type: TRACK_EVENT_TYPES.ACTION }, { sendToPendo: true, sendToHqoTracking: false });
    if (onActionButtonClick) onActionButtonClick(service);
  }, [onActionButtonClick, service]);

  const handleOnClick = useCallback(() => {
    track(
      TRACK_EVENTS.APPOINTMENT_TILE_CLICK,
      { type: TRACK_EVENT_TYPES.ACTION },
      { sendToPendo: true, sendToHqoTracking: false },
    );
    if (onClick) onClick(service);
  }, [onClick, service]);

  return (
    <StyledBookingTile
      formattedPrice={formattedPrice}
      actionButton={actionButton}
      dataTestId={`${service?.resource_uuid}-class-test-id`}
      displayConfirmedIcon={displayConfirmedIcon}
      formattedDate={formattedDate}
      status={status}
      disabled={disabled}
      formattedTimeSlot={formattedTimeSlot}
      onActionButtonClick={handleOnActionButtonClick}
      onClick={handleOnClick}
      title={title}
      subtitle={subtitle}
    />
  );
};
