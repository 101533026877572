import { useMemo } from 'react';
import { Service } from 'store/availableServices/types';
import { useLocale } from 'hooks/use-locale.hook';

interface UseServiceTranslationsProps {
  service: Service;
}
interface UseServicetranslationsReturnValues {
  title: string;
  subtitle: string;
  description: string;
  image_url: string;
  cta_label: string;
}

export const useServiceTranslations = ({
  service,
}: UseServiceTranslationsProps): UseServicetranslationsReturnValues => {
  const locale = useLocale();
  const localeLangAndCountryCodeArray = locale.split('-');
  const capitalizedCountryCode = localeLangAndCountryCodeArray[1]?.toUpperCase();
  const formattedLocale =
    locale === 'en' ? 'en_US' : [localeLangAndCountryCodeArray[0], capitalizedCountryCode].join('_');

  return useMemo(() => {
    return {
      title: service?.translations?.[formattedLocale]?.title ?? service?.title,
      subtitle: service?.translations?.[formattedLocale]?.subtitle ?? service?.subtitle,
      description: service?.translations?.[formattedLocale]?.description ?? service?.description,
      image_url: service?.translations?.[formattedLocale]?.image_url ?? service?.image_url,
      cta_label: service?.translations?.[formattedLocale]?.cta_label ?? service?.cta_label,
    };
  }, [locale, service]);
};
