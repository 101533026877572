import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES, OwnerType } from '../shared/consts';
import { ProviderParams } from '../shared/types';
import { configSelector } from 'store/config/selectors';
import { useLocation, useParams } from 'react-router-dom';
import { getServiceProviders } from 'store/serviceProviders/actions';
import { useEffect } from 'react';
import { selectServiceProviders, selectServiceProvidersStatus } from 'store/serviceProviders/selectors';
import { replace } from 'connected-react-router';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';

export const useV3CompanyUuid = (): void => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const queryParams = useSearchParams();
  const { companyUuid } = useParams<ProviderParams>();
  const { buildingUuid } = useSelector(configSelector);
  const getServiceProvidersStatus = useSelector(selectServiceProvidersStatus);
  const providers = useSelector(selectServiceProviders);

  useEffect(() => {
    if (getServiceProvidersStatus === ACTION_STATUSES.FULFILLED) {
      const companyUuidV3 = providers?.find(provider => provider?.owner_uuid === companyUuid)?.uuid;

      if (companyUuidV3 && companyUuidV3 !== companyUuid) {
        const params = qs.stringify(queryParams);
        dispatch(replace(`${pathname.replace(companyUuid, companyUuidV3)}?${params}`));
      }
    } else if (getServiceProvidersStatus !== ACTION_STATUSES.PENDING) {
      dispatch(
        getServiceProviders.request({
          ownerUuid: buildingUuid,
          ownerType: OwnerType.BUILDING,
        }),
      );
    }
  }, [dispatch, companyUuid, getServiceProvidersStatus]);
};
