import {
  CartContainer,
  ItemsContainer,
  ItemsContainerTitle,
  PriceContainer,
  PromoCodeWrapper,
  TotalPriceBlock,
} from './styles';
import { ORDER_TYPES, TRANSACTION_TYPES } from 'shared/consts';
import { Item } from 'store/cart/types';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks/use-boolean-state';
import { ItemQuantityBlock } from '@hqo/react-components-library/dist/molecules/itemQuantityBlock';

import { PriceBlock } from 'components/price-block';
import React, { useCallback, useEffect, useState } from 'react';
import { formatCurrency } from 'utils/formatCurrency';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectBuildingLocale } from 'store/building/selectors';
import { PromoCodeLink } from 'components/promo-code-link';
import { PromoCodeInput } from 'components/promo-code-input';
import {
  selectCartDiscountSummary,
  selectConfigEnablePromotions,
  selectIsApplyPromoCodeToCartStatusPending,
  selectIsApplyPromoCodeToCartStatusRejected,
  selectIsRemovePromoCodeFromCartStatusRejected,
} from 'store/cart/selectors';
import { DiscountAppliedBlock } from 'components/discount-applied-block';
import { formatDiscount } from 'utils/formatDiscount';
import { applyPromoCodeToCart, removePromoCodeFromCart } from 'store/cart/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { selectTransactionDisplay } from 'store/transactions/selectors';
import { CartProps } from './types';

export const Cart = ({ cart, isCheckoutView, isSwipeModalContent = false }: CartProps): JSX.Element => {
  const intl = useIntl();
  const buildingLocale = useSelector(selectBuildingLocale) || 'en-US';
  const isPromotionEnabledInConfig = useSelector(selectConfigEnablePromotions);
  const discountSummary = useSelector(selectCartDiscountSummary);
  const isRemovePromoCodeFromCartStatusRejected = useSelector(selectIsRemovePromoCodeFromCartStatusRejected);
  const isApplyPromoCodeToCartStatusPending = useSelector(selectIsApplyPromoCodeToCartStatusPending);
  const isApplyPromoCodeToCartStatusRejected = useSelector(selectIsApplyPromoCodeToCartStatusRejected);
  const dispatch = useDispatch();
  const [hasRemovePromoCodeError, setHasRemovePromoCodeError] = useState(false);
  const [hasPromoCodeError, setHasPromoCodeError] = useState(false);
  const { value: displayPromoCode, toggle: toggleDisplayPromoCode } = useBooleanState(false);
  const { transactionUuid } = useSearchParams();
  const transactionDisplay = useSelector(selectTransactionDisplay);

  const isServiceBookingMemberType = transactionUuid
    ? transactionDisplay.type === TRANSACTION_TYPES.MEMBERSHIP
    : cart?.type === ORDER_TYPES.SERVICE_BOOKING_MEMBERSHIP;

  const { currencyType, subtotal, discountApplied, total, taxSummary } = formatSummaryValues(
    cart?.total_summary || transactionDisplay?.total_summary,
  );

  const resolveCheckoutItemSubtitleText = (item: Item) => {
    if (item.type === ORDER_TYPES.SERVICE_BOOKING_APPOINTMENT) {
      return (
        intl.formatMessage({ id: 'with_instructor' }, { instructorName: item.display_info.description1 }) ||
        item.display_info.subtitle
      );
    }
    return '';
  };

  const formatItemPrice = (price: number) => {
    if (!price) {
      return intl.formatMessage({ id: 'free' });
    }
    return formatCurrency(price / 100, currencyType, buildingLocale, true, false);
  };

  const displayPromoCodeLink =
    isServiceBookingMemberType && isCheckoutView && isPromotionEnabledInConfig && !displayPromoCode && !discountSummary;

  const displayPromoCodeInput =
    isServiceBookingMemberType && displayPromoCode && isPromotionEnabledInConfig && !discountSummary;

  const removePromoCode = useCallback(
    promoCode => {
      setHasRemovePromoCodeError(false);
      toggleDisplayPromoCode();
      dispatch(removePromoCodeFromCart.request({ cartId: cart?.id, promoCode }));
    },
    [cart?.id, dispatch],
  );

  const applyPromoCode = useCallback(
    (promoCode: string) => {
      setHasPromoCodeError(false);
      dispatch(applyPromoCodeToCart.request({ cartId: cart.id as string, promoCode }));
    },
    [cart, dispatch],
  );

  useEffect(() => {
    if (isRemovePromoCodeFromCartStatusRejected) {
      setHasRemovePromoCodeError(true);
    }
  }, [isRemovePromoCodeFromCartStatusRejected]);

  useEffect(() => {
    if (isApplyPromoCodeToCartStatusRejected) {
      setHasPromoCodeError(true);
    }
  }, [isApplyPromoCodeToCartStatusRejected]);

  const getItemQuantityBlock = (items: Item[]) => {
    return items?.map(item => (
      <ItemQuantityBlock
        key={item.id}
        title={item.display_info.title}
        subtitle={resolveCheckoutItemSubtitleText(item)}
        quantity={item.quantity}
        price={formatItemPrice(item.price)}
      />
    ));
  };

  return (
    <CartContainer data-testid="cart-container">
      <ItemsContainer isSwipeModalContent={isSwipeModalContent}>
        <ItemsContainerTitle isCheckoutView={isCheckoutView}>
          {intl.formatMessage({ id: 'summary' })}
        </ItemsContainerTitle>
        {getItemQuantityBlock(cart?.items || transactionDisplay?.items)}
      </ItemsContainer>
      {displayPromoCodeLink && (
        <PromoCodeWrapper
          data-testid="promo-code-link"
          isCheckoutView={isCheckoutView}
          isSwipeModalContent={isSwipeModalContent}
        >
          <PromoCodeLink onClick={toggleDisplayPromoCode} isDisabled={!!discountSummary} />
        </PromoCodeWrapper>
      )}
      {displayPromoCodeInput && (
        <PromoCodeWrapper
          data-testid="promo-code-input"
          isCheckoutView={isCheckoutView}
          isSwipeModalContent={isSwipeModalContent}
        >
          <PromoCodeInput
            applyPromoCode={applyPromoCode}
            isDisabled={isApplyPromoCodeToCartStatusPending}
            hasError={hasPromoCodeError}
            setHasError={setHasPromoCodeError}
          />
        </PromoCodeWrapper>
      )}
      {discountSummary &&
        discountSummary.map(discount => (
          <PromoCodeWrapper
            key={discount.name}
            isCheckoutView={isCheckoutView}
            isSwipeModalContent={isSwipeModalContent}
          >
            <DiscountAppliedBlock
              promoCode={discount.promo_code}
              amountOffText={intl.formatMessage(
                { id: 'discount_off' },
                { discount: formatDiscount(discount.amount, discount.type, currencyType, buildingLocale) },
              )}
              removePromoCode={removePromoCode}
              hasError={hasRemovePromoCodeError}
              isCheckoutView={isCheckoutView}
            />
          </PromoCodeWrapper>
        ))}
      <PriceContainer
        isCheckoutView={isCheckoutView}
        data-testid="price-container"
        isSwipeModalContent={isSwipeModalContent}
      >
        <PriceBlock
          title={intl.formatMessage({ id: 'subtotal' })}
          price={formatCurrency(subtotal, currencyType, buildingLocale, true, false)}
        />
        <PriceBlock
          title={intl.formatMessage({ id: 'tax' })}
          price={formatCurrency(taxSummary, currencyType, buildingLocale, true, false)}
        />
        {!!discountApplied && (
          <PriceBlock
            title={intl.formatMessage({ id: 'discount' })}
            price={formatCurrency(discountApplied, currencyType, buildingLocale, true, true)}
          />
        )}
        <TotalPriceBlock
          title={intl.formatMessage({ id: 'total' })}
          price={formatCurrency(total, currencyType, buildingLocale, true, false)}
        />
      </PriceContainer>
    </CartContainer>
  );
};
