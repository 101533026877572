import { BUTTON_VARIANT } from '@hqo/react-components-library';
import { CheckoutContainer, StyledBottomMobileModal } from './styles';
import React from 'react';
import { CheckoutDesktop } from './components/checkout-desktop';
import { CheckoutDesktopModalWrapper } from 'components/modals/checkoutModalWrapper';
import { ContentModal } from './components/content-modal';
import { useIntl } from 'react-intl';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';
import { CheckoutMobile } from './components/checkout-mobile';
import { CheckoutProps } from './types';
import { useModalNavigation } from './hooks/use-modal-navigation.hook';
import { useCheckout } from './hooks/use-checkout.hook';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { Container } from 'hocs/shared-styles';
import { ThreeDSModal } from 'components/modals/3dsModal';

export const Checkout = ({ onCloseCheckout }: CheckoutProps): JSX.Element => {
  const intl = useIntl();
  const isMobileDevice = useIsSmallViewportWidth();
  const { showConfirmCloseModal, reverseAnimation, onStayClick, onLeaveClick, onBackClick, onCloseClick } =
    useModalNavigation(onCloseCheckout);
  const {
    submittedPaymentMethodId,
    paymentError,
    setPaymentError,
    defaultPaymentMethod,
    onRemovePaymentMethod,
    onAddPaymentMethodClick,
    onCancelAddCardClick,
    paymentMethods,
    isCartFulfilled,
    cart,
    cartId,
    showAddCardForm,
    onPayClick,
    is3DSecure,
    setShow3DS,
    show3DS,
  } = useCheckout();
  const buttons = [
    {
      title: intl.formatMessage({ id: 'stay' }),
      handler: onStayClick,
    },
    {
      title: intl.formatMessage({ id: 'leave' }),
      handler: onLeaveClick,
      variant: BUTTON_VARIANT.SECONDARY,
    },
  ];

  return (
    <CheckoutContainer data-testid="checkout-modal">
      {!show3DS ? (
        <>
          {isMobileDevice
            ? isCartFulfilled && (
                <CheckoutMobile
                  cart={cart}
                  onRemovePaymentMethod={onRemovePaymentMethod}
                  currentPaymentMethodId={defaultPaymentMethod}
                  submittedPaymentMethodId={submittedPaymentMethodId}
                  currentPaymentError={paymentError}
                  paymentMethods={paymentMethods}
                  onAddPaymentMethodClick={onAddPaymentMethodClick}
                  onPayClick={onPayClick}
                  isCartFulfilled={isCartFulfilled}
                  showAddCardForm={showAddCardForm}
                  cartId={cartId}
                  onCancelAddCardClick={onCancelAddCardClick}
                />
              )
            : isCartFulfilled && (
                <CheckoutDesktopModalWrapper
                  visible
                  withBackButton
                  onPressBack={onBackClick}
                  content={
                    <CheckoutDesktop
                      cart={cart}
                      onRemovePaymentMethod={onRemovePaymentMethod}
                      currentPaymentMethodId={defaultPaymentMethod}
                      submittedPaymentMethodId={submittedPaymentMethodId}
                      currentPaymentError={paymentError}
                      paymentMethods={paymentMethods}
                      onAddPaymentMethodClick={onAddPaymentMethodClick}
                      onPayClick={onPayClick}
                      showAddCardForm={showAddCardForm}
                      cartId={cartId}
                      onCancelAddCardClick={onCancelAddCardClick}
                    />
                  }
                  onClose={onCloseClick}
                />
              )}
        </>
      ) : (
        <Container data-testid="loading-container" isLoading isModal>
          <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
        </Container>
      )}
      {is3DSecure && (
        <ThreeDSModal
          setPaymentError={setPaymentError}
          setIs3DSVisible={setShow3DS}
          transactionToken={cart?.transaction?.token}
          spreedlyToken={cart?.ui_enablements.spreedly_env_token}
        />
      )}
      <StyledBottomMobileModal
        reverseAnimation={reverseAnimation}
        isVisible={showConfirmCloseModal}
        buttons={buttons}
        content={ContentModal()}
        showCloseIcon={false}
      />
    </CheckoutContainer>
  );
};
