import { IntlShape, useIntl } from 'react-intl';
import { ListWrapper, StyledEmptyContainer } from './styles';
import {
  formatCurrency,
  formatTime,
  isBetweenDatesTime,
  isDurationBeforeCurrentDateTime,
  resolveMembershipPermission,
} from 'utils';

import { AvailableServiceView } from 'store/availableServices/types';
import { BookingTile } from 'components/schedule-list/components/bookingTile';
import { Building } from 'store/building/types';
import { ButtonProps } from '@hqo/react-components-library';
import { EmptyServicesPlaceholder } from 'components/empty-services-placeholder';
import React from 'react';
import { ServiceProviderV3 } from 'store/serviceProviders/types';
import { configSelector } from 'store/config/selectors';
import { getCurrentCurrency } from 'utils/getCurrentCurrency';
import { selectBuilding } from 'store/building/selectors';
import { selectCurrentServiceProvider } from 'store/serviceProviders/selectors';
import { useSelector } from 'react-redux';
import { useTimeZone } from 'hooks/use-timezone.hook';
import moment from 'moment-timezone';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface ScheduleListProps {
  availableServices?: AvailableServiceView[];
  isEmpty?: boolean;
  onClick?: (service: AvailableServiceView) => void;
  onActionButtonClick?: (service: AvailableServiceView) => void;
}

export interface BookingTileProps extends ScheduleListProps {
  service: AvailableServiceView;
  actionButton: ButtonProps;
  displayConfirmedIcon: boolean;
  formattedDate: string;
  formattedPrice: string;
  formattedTimeSlot: string;
  status: string;
  disabled: boolean;
  title: string;
  subtitle: string;
}

const renderEmptyState = (isEmpty: boolean) =>
  isEmpty && (
    <StyledEmptyContainer data-testid="empty-services-container">
      <EmptyServicesPlaceholder />
    </StyledEmptyContainer>
  );

const waitlistDisabled = (
  time: string,
  minutesBefore: number,
  waitlistCount: number,
  capacity?: number,
  timeZone?: string,
) =>
  isDurationBeforeCurrentDateTime(time, minutesBefore, 'minutes', timeZone) || (capacity && capacity <= waitlistCount);

const isServiceDisabled = (service: AvailableServiceView, timeZone?: string) => {
  if (service.seatAvailable <= 0) {
    return (
      !service.canAccess ||
      !service.waitlistAvailable ||
      waitlistDisabled(service.startTime, 30, service.waitlistCount, null, timeZone)
    );
  }
  return false;
};

const renderServiceStatus = (service: AvailableServiceView, intl: IntlShape, timeZone?: string) => {
  if (service.userWaitlisted) {
    return intl.formatMessage({ id: 'added_waitlist' });
  }
  if (service.seatAvailable <= 0 && !service.waitlistAvailable) {
    return intl.formatMessage({ id: 'full_class' });
  }
  if (service.seatAvailable <= 0 && waitlistDisabled(service.startTime, 30, service.waitlistCount, null, timeZone)) {
    return intl.formatMessage({ id: 'not_available' });
  }
  return undefined;
};

const getActionButton = (
  service: AvailableServiceView,
  intl: IntlShape,
  providerDetails: ServiceProviderV3,
  timeZone?: string,
  inLocalTimeLD?: boolean,
) => {
  const isMembershipRequired = resolveMembershipPermission(providerDetails, service.canAccess);

  if (service.seatAvailable <= 0) {
    return service.waitlistAvailable && !waitlistDisabled(service.startTime, 30, service.waitlistCount, null, timeZone)
      ? {
          text: intl.formatMessage({ id: 'waitlist' }),
          variant: 'secondary' as const,
        }
      : undefined;
  }
  if (service.bookingWindow) {
    const isHidden =
      !isBetweenDatesTime(
        new Date(service.bookingWindow.bookable_start),
        new Date(service.bookingWindow.bookable_end),
        moment.tz(timeZone).toDate(),
        timeZone,
        inLocalTimeLD,
      ) || isMembershipRequired;

    return isHidden
      ? undefined
      : {
          text: intl.formatMessage({ id: 'book' }),
          variant: 'secondary' as const,
        };
  }
  return isMembershipRequired
    ? undefined
    : {
        text: intl.formatMessage({ id: 'book' }),
        variant: 'secondary' as const,
      };
};

const getBookingTileProps = (
  service: AvailableServiceView,
  intl: IntlShape,
  timeZone: string,
  locale: string,
  onClick: (service: AvailableServiceView) => void,
  onActionButtonClick: (service: AvailableServiceView) => void,
  providerDetails: ServiceProviderV3,
  building: Building,
  inLocalTimeLD: boolean,
) => {
  const userBooked = service?.userBooked;

  const userWaitlisted = service?.userWaitlisted;

  const actionButton =
    userBooked || userWaitlisted ? undefined : getActionButton(service, intl, providerDetails, timeZone, inLocalTimeLD);

  const formattedDate = service?.startTime ? formatTime(service?.startTime, timeZone, locale, inLocalTimeLD) : 'TBD';

  const formattedPrice = service?.price
    ? formatCurrency(service?.price / 100, getCurrentCurrency(service, building))
    : intl.formatMessage({ id: 'free' });

  const formattedTimeSlot = `${service?.duration} ${intl.formatMessage({ id: 'min' })}`;

  const subtitle = service?.resourceName && `${intl.formatMessage({ id: 'with' })} ${service?.resourceName}`;

  const status = !userBooked && renderServiceStatus(service, intl, timeZone);

  const disabled = !userBooked && isServiceDisabled(service, timeZone);

  return {
    service,
    actionButton,
    displayConfirmedIcon: userBooked,
    formattedDate,
    formattedPrice,
    formattedTimeSlot,
    status,
    disabled,
    onActionButtonClick,
    onClick,
    title: service?.title,
    subtitle,
  } as BookingTileProps;
};

export const ScheduleList = ({ availableServices, isEmpty, onClick, onActionButtonClick }: ScheduleListProps) => {
  const intl = useIntl();
  const timeZone = useTimeZone();
  const building = useSelector(selectBuilding);
  const config = useSelector(configSelector);
  const locale = config.locale || building?.locale;
  const providerDetails = useSelector(selectCurrentServiceProvider);
  const { serviceBookingLocalTimeUpdate: inLocalTimeLD } = useFlags();

  return (
    <>
      {renderEmptyState(isEmpty)}
      {!isEmpty && !!availableServices?.length && (
        <ListWrapper>
          {availableServices.map(service => (
            <BookingTile
              {...getBookingTileProps(
                service,
                intl,
                timeZone,
                locale,
                onClick,
                onActionButtonClick,
                providerDetails,
                building,
                inLocalTimeLD,
              )}
              key={`${service.uuid}_${service.startTime}`}
            />
          ))}
        </ListWrapper>
      )}
    </>
  );
};
