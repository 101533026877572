import { WEEK_LENGTH } from 'shared/consts';
import React, { useCallback, useState } from 'react';
import { formatDateTitle, getDatesInWeekRange, isBeforeCurrentDateTime, isSameOrAfterBaseDateTime } from 'utils';

import { StyledAvailabilityDatePicker } from './styles';
import { useIntl } from 'react-intl';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { configSelector } from 'store/config/selectors';
import { useSelector } from 'react-redux';
import { selectBuilding } from 'store/building/selectors';

const FIRST_INDEX_DAY_OF_WEEK = 0;
const LAST_INDEX_DAY_OF_WEEK = 6;

export interface AvailableServicesDatePickerProps {
  handleDateChange: (date: Date) => void;
  selectedDate: Date;
  cutOffFutureDate?: Date;
}

// eslint-disable-next-line max-lines-per-function
export const AvailableServicesDatePicker = ({
  handleDateChange,
  selectedDate,
  cutOffFutureDate,
}: AvailableServicesDatePickerProps) => {
  const intl = useIntl();
  const timeZone = useTimeZone();
  const building = useSelector(selectBuilding);
  const config = useSelector(configSelector);
  const locale = config.locale || building?.locale;

  const initialDates = getDatesInWeekRange(new Date(), timeZone, 1);

  const [dates, setDates] = useState(initialDates);
  const [title, setTitle] = useState(
    formatDateTitle(new Date(), intl.formatMessage({ id: 'today' }), locale, timeZone),
  );
  const displayLeftArrow = !isBeforeCurrentDateTime(dates[FIRST_INDEX_DAY_OF_WEEK], timeZone);
  const displayRightArrow = cutOffFutureDate
    ? !isSameOrAfterBaseDateTime(cutOffFutureDate, dates[LAST_INDEX_DAY_OF_WEEK], timeZone)
    : true;

  // TODO: refactor to custom hooks https://ventureapp.atlassian.net/browse/SONIC-526
  const handleSelectedDate = useCallback(
    clickedDate => {
      setTitle(formatDateTitle(clickedDate, intl.formatMessage({ id: 'today' }), locale, timeZone));
      handleDateChange(clickedDate);
    },
    [handleDateChange, intl, locale, timeZone],
  );

  const handlePreviousWeek = useCallback(() => {
    const previousDay = new Date(dates[0].setDate(dates[0].getDate() - 2));
    const dateRange = getDatesInWeekRange(previousDay, timeZone, 1);
    setDates(dateRange);
    setTitle(formatDateTitle(dateRange[0], intl.formatMessage({ id: 'today' }), locale, timeZone));
    handleDateChange(dateRange[0]);
  }, [dates, handleDateChange, intl, timeZone, locale]);

  const handleNextWeek = useCallback(() => {
    const dateRange = getDatesInWeekRange(dates[WEEK_LENGTH - 1], timeZone, 1);
    setDates(dateRange);
    setTitle(formatDateTitle(dateRange[0], intl.formatMessage({ id: 'today' }), locale, timeZone));
    handleDateChange(dateRange[0]);
  }, [dates, handleDateChange, intl, timeZone, locale]);

  return (
    <StyledAvailabilityDatePicker
      className="availability-date-picker-container"
      dataTestId="availability-services-date-picker"
      dates={dates}
      dateTitle={title}
      handleSelectedDate={handleSelectedDate}
      locale={locale}
      onLeftArrowClick={handlePreviousWeek}
      onRightArrowClick={handleNextWeek}
      selectedDate={selectedDate}
      showLeftArrow={displayLeftArrow}
      showRightArrow={displayRightArrow}
      timeZone={timeZone}
    />
  );
};
