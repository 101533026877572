import * as actions from './actions';

import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';

export const getBuildingThemeEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getBuildingTheme.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .getBuildingTheme(payload)
        .pipe(
          map(data => {
            return actions.getBuildingTheme.success(data.response);
          }),
          catchError((error: Error) => of(actions.getBuildingTheme.failure({ error }))),
        );
    }),
  );
